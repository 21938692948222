import { Navigate } from "react-router-dom";

import {
  GhgInputService,
  GhgInputServiceUnSubmitError,
} from "./GhgInputPage/ghgInputService";

import {
  GetCategory1ApiError,
  PaperCategory1Api,
  ProcessCategory1Api,
} from "src/app/apis/category1-api/category1Api";
import {
  Category4Api,
  GetCategory4ApiError,
} from "src/app/apis/category4-api/category4Api";
import {
  GetAllInputRequestsApiError,
  InputRequestApi,
} from "src/app/apis/input-request-api/inputRequestApi";
import {
  getLocalStorageApi,
  GetLocalStorageError,
  LocalStorage,
} from "src/app/apis/local-storage-api";
import {
  GetPaperFactoryCategory1200,
  GetProcessFactoryCategory1200,
} from "src/app/apis/model";
import { FactoryListTemplate } from "src/app/components/templates";
import { envConfig, messages } from "src/app/configs";
import { useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { ToDateTimeYearFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

/**
 * ローカルストレージのバージョンをチェックする。
 * @param localStorageInput
 * @returns true:バージョン一致 false:バージョン不一致
 */
export const versionCheck = (localStorageInput: LocalStorage): boolean => {
  // ローカルストレージに値がそろっていない場合はスキップ
  if (
    localStorageInput.energyInput === undefined ||
    localStorageInput.wasteInput === undefined ||
    localStorageInput.manufacturingInput === undefined ||
    localStorageInput.transportInput === undefined
  ) {
    return true;
  }

  // バージョンをチェック
  if (
    localStorageInput.energyInput.version !== envConfig.version ||
    localStorageInput.wasteInput.version !== envConfig.version ||
    localStorageInput.manufacturingInput.version !== envConfig.version ||
    localStorageInput.transportInput.version !== envConfig.version
  ) {
    return false;
  }

  return true;
};

export const FactoryListPage: React.FC = () => {
  const snackbar = useCustomSnackbar();
  const [authState, setAuthState] = useAuthState<AuthState>();

  const yearDropdownProps = useYearDropdown();

  const { isProcessing, data } = useAsyncEffect(async () => {
    const inputRequestApi = new InputRequestApi();
    if (yearDropdownProps?.year === undefined) {
      return;
    }
    try {
      const inputRequests = await inputRequestApi.getAllInputRequests({
        year: yearDropdownProps.year,
      });

      let paperCategory1Result: GetPaperFactoryCategory1200;
      let processCategory1Result: GetProcessFactoryCategory1200;
      // サプライヤ（製紙）の場合
      if (authState?.role === "paper-supplier") {
        const paperCategory1Api = new PaperCategory1Api();
        // SDA013:製紙工場カテゴリ1取得API
        paperCategory1Result = await paperCategory1Api.getCategory1({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      }
      // サプライヤ（加工）の場合
      if (authState?.role === "process-supplier") {
        const processCategory1Api = new ProcessCategory1Api();
        // SDA014:加工工場カテゴリ1取得API
        processCategory1Result = await processCategory1Api.getCategory1({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      }

      //カテゴリ4取得
      const category4Api = new Category4Api();
      const category4Result = await category4Api.getCategory4({
        year: yearDropdownProps.year.toFormat("yyyy"),
      });

      // アプリのバージョンを確認
      await Promise.all(
        inputRequests.requests.map(async (it) => {
          let paperCategory1factory;
          let processCategory1factory;
          let category1version = 0;

          if (authState?.role === "paper-supplier") {
            paperCategory1factory = paperCategory1Result?.factories.find(
              (info) => !info.deleteFlag && info.factoryId === it.factoryId
            );
            if (paperCategory1factory !== undefined) {
              category1version = paperCategory1factory.version;
            }
          }

          if (authState?.role === "process-supplier") {
            processCategory1factory = processCategory1Result?.factories.find(
              (info) => !info.deleteFlag && info.factoryId === it.factoryId
            );
            if (processCategory1factory !== undefined) {
              category1version = processCategory1factory.version;
            }
          }

          const category4factory = category4Result.factories.find(
            (info) => !info.deleteFlag && info.factoryId === it.factoryId
          );

          let category4version = 0;
          if (category4factory !== undefined) {
            category4version = category4factory.version;
          }

          // 提出期限を過ぎていたらスキップ
          if (it.status === "confirmed" || it.status === "expired") {
            return;
          }

          //ローカルストレージ取得
          const localStorageApi = getLocalStorageApi();
          const localStorageInput = await localStorageApi.get({
            year: yearDropdownProps.year.toFormat("yyyy"),
            factoryId: it.factoryId,
          });

          // バージョンをチェック
          if (!versionCheck(localStorageInput)) {
            const ghgInputService = new GhgInputService();
            for (let times = 1; times <= 2; times++) {
              await ghgInputService.unSubmit({
                factoryId: it.factoryId,
                year: yearDropdownProps.year,
                intensityCategory: times === 1 ? "category1" : "category4",
                version: times === 1 ? category1version : category4version,
              });
            }
          }
        })
      );

      // 再度入力依頼一覧を取得
      const res = await inputRequestApi.getAllInputRequests({
        year: yearDropdownProps.year,
      });

      return {
        companyId: res.companyId,
        year: ToDateTimeYearFromString(res.year),
        expirationTime: res.expirationTime,
        beginTime: res.beginTime,
        isWarning: res.requests.some(
          (it) => it.status === "requested" || it.status === "in-progress"
        ),
        requests: res.requests.map((request) => {
          return {
            factoryId: request.factoryId,
            factoryName: request.factoryName,
            factoryNameForSelf: request.factoryNameForSelf,
            factoryNameForOthers: request.factoryNameForOthers,
            factoryType: request.factoryType,
            status: request.status,
            category1: request.category1,
            category4: request.category4,
          };
        }),
      };
    } catch (err) {
      if (
        !(err instanceof GetAllInputRequestsApiError) &&
        !(err instanceof GetLocalStorageError) &&
        !(err instanceof GhgInputServiceUnSubmitError) &&
        !(err instanceof GetCategory1ApiError) &&
        !(err instanceof GetCategory4ApiError)
      ) {
        snackbar.error(messages.common.unknown);
        return;
      }
      const errType = err.type;
      switch (errType) {
        case "can-not-use":
          snackbar.error(messages.localStorage.canNotUse);
          break;
        case "cookie-disabled":
          snackbar.error(messages.localStorage.cookieDisabled);
          break;
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          return;
        case "network":
          snackbar.error(messages.common.network);
          return;
        case "expired":
          snackbar.error(messages.ghgInput.unSubmit.expired);
          return;
        case "unknown":
          snackbar.error(messages.common.unknown);
          return;
        case "conflict":
          snackbar.error(messages.ghgInput.unSubmit.conflict);
          return;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }
  }, [yearDropdownProps?.year]);

  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return <Navigate to="/input" />;
  }
  return (
    <FactoryListTemplate
      expirationTime={data?.expirationTime}
      beginTime={data?.beginTime}
      requests={data?.requests}
      isProcessing={isProcessing}
      isWarning={data?.isWarning ?? false}
      yearDropdownProps={yearDropdownProps}
      year={yearDropdownProps.year}
    />
  );
};

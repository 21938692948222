import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { CompanyType } from "src/app/apis/model";
import { Dropdown, DropdownProps } from "src/app/components/atoms";
import {
  ChartAndTableView,
  DataField,
  SingleBarChart,
} from "src/app/components/organisms";
import { TotalEmission } from "src/app/models";
import { FlexBox } from "src/lib/components/atoms";

export type Category1IntensityByProductTemplateProps = {
  isProcessing: boolean;
  companyType: Exclude<CompanyType, "user-company">;

  // グラフ用Props
  chartData: TotalEmission[];
  productDropdownProps: DropdownProps;
  unit: string;

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

const useStyles = makeStyles((theme) => ({
  dropDown: { maxWidth: 480, width: "100%" },
}));

export const Category1IntensityByProductTemplate: React.FC<
  Category1IntensityByProductTemplateProps
> = (props) => {
  const classes = useStyles();

  return (
    <ChartAndTableView
      title={`カテゴリ1　工場別${
        props.companyType === "paper-supplier" ? " × 製品別" : ""
      }（プロセス排出原単位）`}
      dropdownElement={
        props.companyType === "process-supplier" ? undefined : (
          <FlexBox alignItems="center">
            <Typography variant="h4">製品</Typography>
            <Box className={classes.dropDown} ml={1}>
              <Dropdown {...props.productDropdownProps} />
            </Box>
          </FlexBox>
        )
      }
      chartElement={
        <SingleBarChart
          data={props.chartData}
          unit={`(${props.unit})`}
          companyType={props.companyType}
          barColor={["#C5DEB9", "#98C186"]}
          chartType="normal"
          intensity
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};

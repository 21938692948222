import { CloseRounded } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { ManufacturingInput } from "./ManufacturingInput";

import { GetProduct200ProductsItem } from "src/app/apis/model";
import { Text } from "src/app/components/atoms";
import { TitledArea } from "src/app/components/molecules";
import { ConfirmationDialog } from "src/app/components/organisms";
import { GhgInputCategory1Form, MachineForm } from "src/app/components/pages";
import { messages } from "src/app/configs";
import { MachineName } from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    padding: theme.spacing(0.5),
  },
}));

export type MachineInputProps = {
  machineIndex: number;
  machine: MachineForm;
  products: GetProduct200ProductsItem[];
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  isEditable: boolean;
  isLastMachine: boolean;
};

export const MachineInput: React.FC<MachineInputProps> = React.memo((props) => {
  const classes = useStyles();

  const { setForm } = props;
  const onClickDeleteMachine = () => {
    setForm(
      produce((form) => {
        const index = form.manufacturing.machines.findIndex(
          (it) => it.key === props.machine.key
        );
        if (index !== -1) {
          form.manufacturing.machines.splice(index, 1);
        }
      })
    );
  };

  const { openDialog, dialogProps } = useConfirmationDialog(
    messages.ghgInput.manufacturing.deleteMachine(props.machineIndex),
    onClickDeleteMachine
  );

  const deleteMachineButtonProps = {
    onClick: openDialog,
    disabled: !props.isEditable,
  };

  const bindTextField = useBindTextField(props.machine, (machineAction) =>
    setForm(
      produce<GhgInputCategory1Form>((form) => {
        const index = form.manufacturing.machines.findIndex(
          (it) => it.key === props.machine.key
        );
        if (index === -1) {
          return;
        }
        if (machineAction instanceof Function) {
          form.manufacturing.machines[index] = machineAction(
            form.manufacturing.machines[index]
          );
        } else {
          form.manufacturing.machines[index] = machineAction;
        }
      })
    )
  );

  const machineNameBind = bindTextField({
    validator: MachineName.Rule,
    selector: (form) => form.machineName,
    isRequired: true,
  });

  return (
    <TitledArea
      mt={3}
      title={
        <FlexBox flex={1} alignItems="center" justifyContent="space-between">
          <FlexBox flex={1}>
            <Typography variant="caption" mt="6px">
              No.{props.machineIndex + 1}
            </Typography>
            <Box ml={1} maxWidth={360} width="100%">
              <Text
                {...machineNameBind}
                maxLength={MachineName.MaxLength}
                disabled={!props.isEditable}
              />
              {machineNameBind.hasError && (
                <Typography
                  variant="overline"
                  color="error"
                  display="flex"
                  lineHeight={1}
                  mt="2px"
                >
                  {MachineName.Message}
                </Typography>
              )}
            </Box>
          </FlexBox>
          {!props.isLastMachine && (
            <IconButton
              {...deleteMachineButtonProps}
              className={classes.deleteButton}
              size="large"
            >
              <CloseRounded />
            </IconButton>
          )}
        </FlexBox>
      }
    >
      <ManufacturingInput
        machine={props.machine}
        products={props.products}
        setForm={setForm}
        isEditable={props.isEditable}
        bindPaperTextField={bindTextField}
        companyType="paper-supplier"
      />

      <ConfirmationDialog {...dialogProps} />
    </TitledArea>
  );
});

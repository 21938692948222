import { Schedule, Warning } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import React from "react";

import { AppCard } from "src/app/components/atoms";
import {
  FactoryListTable,
  ImageHeading,
  YearDropdown,
  YearDropdownProps,
  Content,
} from "src/app/components/molecules";
import { messages, theme as themes } from "src/app/configs";
import { InputRequest } from "src/app/models";
import { ToDateTimeFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  time: { color: theme.palette.text.disabled, fontWeight: "bold" },
  message: { color: theme.palette.secondary.main, fontWeight: "bold" },
  inHouse: {
    color: theme.palette.text.disabled,
  },
  ghgInput: {
    color: theme.palette.text.disabled,
  },
  typeBox: {
    width: 80,
    minWidth: 80,
    height: 26,
    borderRadius: "4px",
    border: "1px solid",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export type FactoryProps = {
  expirationTime?: string;
  beginTime?: string;
  requests?: InputRequest[];
  isProcessing: boolean;
  isWarning: boolean;
  yearDropdownProps: YearDropdownProps;
  year: DateTime;
};

export const FactoryListTemplate: React.FC<FactoryProps> = (props) => {
  const classes = useStyles();
  const beginTimeForDisplay =
    props.beginTime === undefined || props.beginTime.length === 0
      ? "----/--/-- --:--"
      : ToDateTimeFromString(props.beginTime).toFormat("yyyy/MM/dd HH:mm");
  const expirationTimeForDisplay =
    props.expirationTime === undefined || props.expirationTime.length === 0
      ? "----/--/-- --:--"
      : ToDateTimeFromString(props.expirationTime).toFormat("yyyy/MM/dd HH:mm");

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h1">GHG算出情報入力</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <AppCard>
        <ImageHeading title="入力依頼一覧" variant="factory" />

        <Grid container alignItems="center" mt={2} rowGap={2}>
          <Grid item xs={12} md={7} lg={8}>
            <FlexBox flexDirection="column" justifyContent="center">
              <FlexBox>
                <Schedule className={classes.time} fontSize="small" />
                <Typography variant="body2" className={classes.time} ml={0.5}>
                  入力期間　{beginTimeForDisplay} ～ {expirationTimeForDisplay}
                </Typography>
              </FlexBox>

              {props.isWarning && (
                <FlexBox mt="5px">
                  <Warning className={classes.message} fontSize="small" />
                  <Typography
                    variant="body2"
                    className={classes.message}
                    ml={0.5}
                  >
                    {messages.ghgRequest.uncompleted}
                  </Typography>
                </FlexBox>
              )}
            </FlexBox>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <FlexBox flexDirection="column">
              <FlexBox alignItems="center">
                <FlexBox
                  className={classes.typeBox}
                  borderColor={themes.palette.statusDeadline}
                  color={themes.palette.statusDeadline}
                >
                  <Typography variant="h6" lineHeight={1}>
                    自社算定
                  </Typography>
                </FlexBox>
                <Typography variant="h6" className={classes.inHouse} ml={0.5}>
                  ：自社で算定した結果を入力する場合
                </Typography>
              </FlexBox>

              <FlexBox alignItems="center" mt={0.5}>
                <FlexBox
                  className={classes.typeBox}
                  borderColor={themes.palette.category4Badge}
                  color={themes.palette.category4Badge}
                >
                  <Typography variant="h6" lineHeight={1}>
                    TORCH
                  </Typography>
                </FlexBox>
                <Typography variant="h6" className={classes.ghgInput} ml={0.5}>
                  ：算定元情報を入力し、TORCHで算定する場合
                </Typography>
              </FlexBox>
            </FlexBox>
          </Grid>
        </Grid>

        <Box mt={2}>
          <FactoryListTable
            requests={props.requests}
            isProcessing={props.isProcessing}
            year={props.year}
          />
        </Box>
      </AppCard>
    </Content>
  );
};

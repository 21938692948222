import produce, { Draft } from "immer";
import { Dispatch, SetStateAction, useCallback } from "react";

import { Field } from "src/app/components/pages";
import { isNotEmpty } from "src/lib/utils/validationUtil";

export const useBindTextField = <T>(
  form: T,
  setForm: Dispatch<SetStateAction<T>>
) =>
  useCallback(
    (params: {
      selector: (form: T | Draft<T>) => Field | Draft<Field> | undefined;
      validator: (value: string) => boolean;
      isRequired: boolean;
      padDecimal?: number;
    }) => {
      const { selector, validator, isRequired, padDecimal } = params;

      const target = selector(form);
      return {
        value: target?.value ?? "",
        hasError: target?.error === true ?? false,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          setForm(
            produce<T>((form) => {
              const target = selector(form);
              if (target !== undefined) {
                target.value = event.target.value;
              }
            })
          );
        },
        onBlur: () => {
          setForm(
            produce<T>((form) => {
              const target = selector(form);
              if (target !== undefined) {
                const tmpValue = getFormatValue(target.value, padDecimal);
                switch (isRequired) {
                  case true:
                    const validateRequired = isNotEmpty();
                    target.error =
                      !validateRequired(tmpValue) || !validator(tmpValue);
                    break;
                  case false:
                    target.error = !validator(tmpValue);
                    break;
                }
                if (!target.error) {
                  target.value = tmpValue;
                }
              }
            })
          );
        },
      };
    },
    [form, setForm]
  );

export const getFormatValue = (value?: string, padDecimal?: number) => {
  if (value === undefined || value === "") {
    return "";
  }
  if (padDecimal === undefined || isNaN(Number(value))) {
    return value;
  }
  const pow = Math.pow(10, padDecimal);
  const roundValue = Math.round(Number(value) * pow) / pow;
  return roundValue.toFixed(padDecimal);
};

import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { bindTextField, StringType, useForm } from "@somq14/use-form";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import { CompanyType, IntensityType } from "src/app/apis/model";
import { FactoryIntensity, DropdownProps } from "src/app/components/atoms";
import { Category4IntensityByDestinationTemplate } from "src/app/components/templates";
import { Category4DataForDisplay } from "src/app/hooks";
import { TotalEmission } from "src/app/models";
import {
  formatIntensity,
  getProcessNameForVisual,
  padIntensity,
} from "src/app/utils/computeForVisuals";
import { DropDownItem } from "src/lib/components/atoms";

export type Category4IntensityByDestinationPageProps = {
  category4Data?: Category4DataForDisplay;
  companyType: Exclude<CompanyType, "user-company">;
  isProcessing: boolean;
};

const convertCategory4DataToChartProps = (
  selectedFactoryId: string,
  category4Data?: Category4DataForDisplay
) => {
  const chartData: TotalEmission[] = [];

  const selectedFactory = category4Data?.factories?.find(
    (factory) => factory.factoryId === selectedFactoryId
  );
  if (selectedFactory !== undefined) {
    selectedFactory.byDestination.forEach((destination) => {
      if (destination.tonKmBasedCo2Intensity !== undefined) {
        chartData.push({
          dataName: destination.factoryNameForSelf,
          value: destination.tonKmBasedCo2Intensity,
        });
      }
    });
  }

  return { chartData };
};

const convertCategory4DataToTableProps = (
  category4Data: Category4DataForDisplay | undefined,
  companyType: Exclude<CompanyType, "user-company">
) => {
  const processName = getProcessNameForVisual(companyType, "category4");

  const tableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "納入元工場",
      flex: 1,
    },
    {
      field: "destinationName",
      headerName: "納入先工場",
      flex: 1,
    },
    {
      field: "co2Intensity",
      headerName: `${processName}原単位(kg-CO2eq/tkm)`,
      flex: 1,
      renderCell: (params) => getIntensity(params),
    },
  ];

  const tableData = [];
  if (category4Data?.factories !== undefined) {
    for (const factory of category4Data.factories) {
      if (factory.intensityType !== "in-house") {
        for (const it of factory.byDestination) {
          if (it.tonKmBasedCo2Intensity !== undefined) {
            tableData.push({
              id: factory.factoryId + it.factoryType + uuid(),
              factoryName: factory.factoryNameForSelf,
              destinationName: it.factoryNameForSelf,
              co2Intensity: padIntensity(
                formatIntensity(it.tonKmBasedCo2Intensity),
                3
              ),
              intensityType: factory.intensityType,
            });
          }
        }
      } else {
        if (factory.tonKmBasedCo2Intensity !== undefined) {
          tableData.push({
            id: factory.factoryId + uuid(),
            factoryName: factory.factoryNameForSelf,
            destinationName: "－",
            co2Intensity: padIntensity(
              formatIntensity(factory.tonKmBasedCo2Intensity),
              3
            ),
            intensityType: factory.intensityType,
          });
        }
      }
    }
  }

  const fileName = `カテゴリ４納入先別（原単位）_${category4Data?.year.toFormat(
    "yyyy"
  )}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const Category4IntensityByDestinationPage: React.FC<
  Category4IntensityByDestinationPageProps
> = (props) => {
  const factoryList: DropDownItem[] =
    props.category4Data === undefined
      ? []
      : props.category4Data?.factories.map((it) => {
          return {
            value: it.factoryId,
            displayName: it.factoryNameForSelf,
          };
        });

  const form = useForm<{ selectedFactory: string }>({
    selectedFactory: {
      type: StringType,
    },
  });

  const dropdownFirstFactoryId =
    factoryList.length > 0 ? factoryList[0].value : undefined;
  useEffect(() => {
    if (dropdownFirstFactoryId !== undefined) {
      form.fields.selectedFactory.setValue(dropdownFirstFactoryId);
    }
    // あえて form.fields.selectedFactory.setValue を配列に入れない
    // これは state が変わるたびに別の参照になるため、追加すると無限ループになってしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownFirstFactoryId]);

  const factoryDropdownProps: DropdownProps = {
    items: factoryList,
    ...bindTextField(form.fields.selectedFactory),
  };

  const chartProps = convertCategory4DataToChartProps(
    form.fields.selectedFactory.value,
    props.category4Data
  );
  const tableProps = convertCategory4DataToTableProps(
    props.category4Data,
    props.companyType
  );

  return (
    <Category4IntensityByDestinationTemplate
      isProcessing={props.isProcessing}
      {...chartProps}
      {...tableProps}
      factoryDropdownProps={factoryDropdownProps}
    />
  );
};

const getIntensity = (params: GridRenderCellParams) => {
  const { intensityType } = params.row as { intensityType: IntensityType };
  return (
    <Box display="flex">
      <FactoryIntensity intensityType={intensityType} />

      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : params.value}
      </Box>
    </Box>
  );
};

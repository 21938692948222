import BigNumber from "bignumber.js";

import { ConverterProvider } from "./converter.provider";
import { EmissionMathProvider } from "./emission-math.provider";

import {
  ClassificationType,
  FactoryType,
  MaterialType,
} from "src/app/apis/model";
import { paperMaterialTypes, processMaterialTypes } from "src/app/codes";
import {
  EmissionFactorModel,
  EnergyInputModel,
  MachineModel,
  ProductModel,
  WasteInputModel,
} from "src/app/models";
import { materialClassificationMaster } from "src/app/scope3-logic/master";
import { TypedException } from "src/lib/exception/exception";
import { total } from "src/lib/utils/bignumberUtil";

export class Category1ProviderException extends TypedException<
  "emission-factor-not-found" | "machine-count-incorrect" | "amount-not-found"
> {}

export type EmissionByMachine = {
  privateSteamCo2Emission: BigNumber;
  privatePowerCo2Emission: BigNumber;
  purchasedPowerCo2Emission: BigNumber;
  certificateCo2Emission: BigNumber;
  waterCo2Emission: BigNumber;
  byMaterial: {
    materialType: MaterialType;
    co2Emission: BigNumber;
  }[];
};

export type ProductionByMachine = {
  productType: string;
  amount: BigNumber;
  weight: BigNumber;
}[];

export class Category1Provider {
  private readonly unitConversion = new BigNumber("3.6"); // 単位電力量あたりのエネルギー[MJ/kWh]
  private readonly powerGenerationEfficiency = new BigNumber("0.27"); // 発電効率[%]

  constructor(
    private readonly converter: ConverterProvider,
    private readonly emissionMathProvider: EmissionMathProvider
  ) {}

  /**
   * ※このクラス内のあちこちから呼ばれている
   */
  calcCo2Emission(input: {
    factor: BigNumber | undefined;
    amount: BigNumber;
  }): BigNumber {
    return input.factor === undefined
      ? new BigNumber(0)
      : input.amount.times(input.factor);
  }

  /**
   * ※getByProductとgetByClassificationから呼ばれている
   */
  calcCo2Intensity(input: {
    co2Emission: BigNumber;
    amount: BigNumber;
  }): BigNumber | undefined {
    return input.amount.isZero()
      ? undefined
      : input.co2Emission.div(input.amount).times(1000); // 原単位はt-CO2eq/〇からkg-CO2eq/〇に変換する必要があるため1000倍する
  }

  /**
   * マシンごとに各種排出量を計算する。
   * ※getCategory1から呼ばれている
   */
  calcEmissionByMachine(input: {
    machines: MachineModel[];
    materialTypes: readonly MaterialType[];
    energyInput: Required<EnergyInputModel>;
    factors: {
      ownSteamEmissionFactor: BigNumber | undefined;
      ownElectricEmissionFactor: BigNumber | undefined;
      electricEmissionFactorWithoutCertificates: BigNumber | undefined;
      industrialWaterEmissionFactor: BigNumber | undefined;
      groundWaterEmissionFactor: BigNumber | undefined;
      emissionFactors: EmissionFactorModel[];
    };
    privateSteamCo2EmissionItems?: {
      boilerCo2Emission: BigNumber;
      coGeneratorCo2Emission: BigNumber;
    };
  }): EmissionByMachine[] {
    const usedElectricPowerInFactory = BigNumber.max(
      0,
      total(
        input.energyInput.generator.occurredElectricPower,
        input.energyInput.coGenerator.occurredElectricPower,
        input.energyInput.solar.occurredElectricPower,
        input.energyInput.hydroPower.occurredElectricPower,
        ...input.energyInput.electricPowerPurchases.map((it) => it.amount),
        input.energyInput.energySale.electricPower.negated()
      )
    );

    const summary = {
      usedElectricPowerInFactory,
      totalCertificateElectricPower: total(
        ...input.energyInput.greenCertificates.map((it) => it.amount),
        ...input.energyInput.jCredits.map((it) => it.amount)
      ),
      electricPowerPurchaseRatio: usedElectricPowerInFactory.isZero()
        ? new BigNumber(1)
        : total(
            ...input.energyInput.electricPowerPurchases.map((it) => it.amount)
          ).div(usedElectricPowerInFactory),
      totalPurchasePowerCo2Emission: total(
        ...input.energyInput.electricPowerPurchases.map((it) =>
          this.calcCo2Emission({
            factor: it.emissionFactor,
            amount: it.amount,
          })
        )
      ),
      totalMachineElectricPower: total(
        ...input.machines.map((it) => it.electricPowerConsumption)
      ),
    };

    return input.machines.map((machine) => {
      const machineEmission = this.calcMachineEmission({
        machine,
        materialTypes: input.materialTypes,
        factors: input.factors,
        summary,
        privateSteamCo2EmissionItems: input.privateSteamCo2EmissionItems,
      });

      return machineEmission;
    });
  }

  /**
   * あるマシンの各種排出量を計算する。
   * ※calcEmissionByMachineから呼ばれている
   */
  calcMachineEmission(input: {
    machine: MachineModel;
    materialTypes: readonly MaterialType[];
    factors: {
      ownSteamEmissionFactor: BigNumber | undefined;
      ownElectricEmissionFactor: BigNumber | undefined;
      electricEmissionFactorWithoutCertificates: BigNumber | undefined;
      industrialWaterEmissionFactor: BigNumber | undefined;
      groundWaterEmissionFactor: BigNumber | undefined;
      emissionFactors: EmissionFactorModel[];
    };
    summary: {
      usedElectricPowerInFactory: BigNumber;
      totalCertificateElectricPower: BigNumber;
      electricPowerPurchaseRatio: BigNumber;
      totalPurchasePowerCo2Emission: BigNumber;
      totalMachineElectricPower: BigNumber;
    };
    // 加工の場合に必須
    privateSteamCo2EmissionItems?: {
      boilerCo2Emission: BigNumber;
      coGeneratorCo2Emission: BigNumber;
    };
  }): EmissionByMachine {
    const byMaterial = input.materialTypes.map((materialType) => {
      const amount = total(
        ...input.machine.materialConsumptions
          .filter((it) => it.materialType === materialType)
          .map((it) => it.amount)
      );
      const factor = input.factors.emissionFactors.find(
        (it) => it.target === materialType
      );
      if (factor === undefined) {
        throw new Category1ProviderException(
          "emission-factor-not-found",
          undefined,
          undefined
        );
      }
      return {
        materialType,
        co2Emission: this.calcCo2Emission({
          factor: factor.co2EmissionFactor,
          amount,
        }),
      };
    });

    const privateSteamCo2Emission =
      input.privateSteamCo2EmissionItems === undefined
        ? this.calcCo2Emission({
            factor: input.factors.ownSteamEmissionFactor,
            amount: input.machine.steamConsumption,
          })
        : total(
            this.calcCo2Emission({
              factor: input.factors.ownSteamEmissionFactor,
              amount: input.machine.steamConsumption,
            }),
            input.privateSteamCo2EmissionItems.boilerCo2Emission,
            input.privateSteamCo2EmissionItems.coGeneratorCo2Emission
          );

    return {
      privateSteamCo2Emission,
      privatePowerCo2Emission: this.calcCo2Emission({
        factor: input.factors.ownElectricEmissionFactor,
        // マシンが使用した自家電力 [kWh] = マシンが使用した電力 [kWh] x (1 - 購入電力の割合)
        amount: input.machine.electricPowerConsumption.times(
          new BigNumber(1).minus(input.summary.electricPowerPurchaseRatio)
        ),
      }),
      // このマシンの購入電力由来排出量 [tCO2eq] = 購入電力由来排出量 [tCO2eq] x このマシンの電力消費量 [kWh] / 工場内の電力消費量 [kWh]
      purchasedPowerCo2Emission:
        input.summary.usedElectricPowerInFactory.isZero()
          ? new BigNumber(0)
          : input.summary.totalPurchasePowerCo2Emission
              .times(input.machine.electricPowerConsumption)
              .div(input.summary.usedElectricPowerInFactory),
      certificateCo2Emission: this.calcCo2Emission({
        factor: input.factors.electricEmissionFactorWithoutCertificates,
        // このマシンの証書の電力量 [tCO2eq] = 証書の電力量合計 [tCO2eq] x このマシンの電力消費量 [kWh] / 全マシンの総電力消費量 [kWh]
        amount: input.summary.totalMachineElectricPower.isZero()
          ? new BigNumber(0)
          : input.summary.totalCertificateElectricPower
              .times(input.machine.electricPowerConsumption)
              .div(input.summary.totalMachineElectricPower),
      }).negated(),
      waterCo2Emission: total(
        this.calcCo2Emission({
          factor: input.factors.industrialWaterEmissionFactor,
          amount: input.machine.industrialWaterConsumption,
        }),
        this.calcCo2Emission({
          factor: input.factors.groundWaterEmissionFactor,
          amount: input.machine.groundWaterConsumption,
        })
      ),
      byMaterial,
    };
  }

  /**
   * 製品ごとの排出量を計算する
   * マシンごとの排出量などを製品ごとに振り分ける
   * ※getByProductから呼ばれている
   */
  calcEmissionByProduct(input: {
    factoryType: FactoryType;
    wasteEmission: BigNumber;
    drainageEmission: BigNumber;
    emissionsByMachine: EmissionByMachine[];
    productionsByMachine: ProductionByMachine[];
    products: ProductModel[];
  }): { productType: string; co2Emission: BigNumber }[] {
    const totalProductionWeight = total(
      ...input.productionsByMachine.map((production) =>
        total(...production.map((it) => it.weight))
      )
    );

    return input.products.map(({ productType }) => {
      const productWeight = total(
        ...input.productionsByMachine.map((production) =>
          total(
            ...production
              .filter((it) => it.productType === productType)
              .map((it) => it.weight)
          )
        )
      );

      const wasteEmissionByProduct = totalProductionWeight.isZero()
        ? new BigNumber(0)
        : input.wasteEmission.times(productWeight).div(totalProductionWeight);

      const drainageEmissionByProduct = totalProductionWeight.isZero()
        ? new BigNumber(0)
        : input.drainageEmission
            .times(productWeight)
            .div(totalProductionWeight);

      const productEmissionsByMachine = input.emissionsByMachine.map(
        (machine, index) => {
          return this.calcProductEmissionByMachine({
            machine,
            productType,
            production: input.productionsByMachine[index],
            products: input.products,
          });
        }
      );

      return {
        productType,
        co2Emission: total(
          wasteEmissionByProduct,
          drainageEmissionByProduct,
          ...productEmissionsByMachine.map((machine) =>
            total(
              machine.privateSteamCo2Emission,
              machine.privatePowerCo2Emission,
              machine.purchasedPowerCo2Emission,
              machine.certificateCo2Emission,
              machine.waterCo2Emission,
              ...machine.byMaterial.map((it) => it.co2Emission)
            )
          )
        ),
      };
    });
  }

  /**
   * あるマシンで製造したある製品の排出量を計算する
   * ※calcEmissionByProductから呼ばれている
   */
  calcProductEmissionByMachine(input: {
    productType: string;
    machine: EmissionByMachine;
    production: ProductionByMachine;
    products: ProductModel[];
  }): EmissionByMachine {
    const totalProductWeight = total(
      ...input.production.map((it) => it.weight)
    );
    const productWeight = total(
      ...input.production
        .filter((it) => it.productType === input.productType)
        .map((it) => it.weight)
    );

    const productWeightRatio = totalProductWeight.isZero()
      ? new BigNumber(0) // 排出量の計算に用いるため 0 で OK
      : productWeight.div(totalProductWeight);

    return {
      privateSteamCo2Emission:
        input.machine.privateSteamCo2Emission.times(productWeightRatio),
      privatePowerCo2Emission:
        input.machine.privatePowerCo2Emission.times(productWeightRatio),
      purchasedPowerCo2Emission:
        input.machine.purchasedPowerCo2Emission.times(productWeightRatio),
      certificateCo2Emission:
        input.machine.certificateCo2Emission.times(productWeightRatio),
      waterCo2Emission:
        input.machine.waterCo2Emission.times(productWeightRatio),
      byMaterial: input.machine.byMaterial.map(
        ({ materialType, co2Emission }) => {
          const relatedProductTypes = input.products
            .filter((it) => it.materialTypes.includes(materialType))
            .map((it) => it.productType);

          // この製品はこのマテリアルを使わないため、按分の対象外
          if (!relatedProductTypes.includes(input.productType)) {
            return {
              materialType,
              co2Emission: new BigNumber(0),
            };
          }

          const totalRelatedProductWeight = total(
            ...input.production
              .filter((it) => relatedProductTypes.includes(it.productType))
              .map((it) => it.weight)
          );

          return {
            materialType,
            co2Emission: totalRelatedProductWeight.isZero()
              ? new BigNumber(0)
              : co2Emission.times(productWeight).div(totalRelatedProductWeight),
          };
        }
      ),
    };
  }

  /**
   * ※getCategory1から呼ばれている
   */
  getByProduct(input: {
    factoryType: FactoryType;
    wasteEmission: BigNumber;
    drainageEmission: BigNumber;
    emissionsByMachine: EmissionByMachine[];
    productionsByMachine: ProductionByMachine[];
    productToWeightTable: Partial<Record<string, BigNumber>>;
    products: ProductModel[];
  }): {
    productType: string;
    co2Emission: BigNumber;
    weightBasedCo2Intensity?: BigNumber;
    amountBasedCo2Intensity?: BigNumber;
  }[] {
    const byProductEmission = this.calcEmissionByProduct(input);
    return byProductEmission.map(({ productType, co2Emission }) => {
      const amount = total(
        ...input.productionsByMachine.map((production) =>
          total(
            ...production
              .filter((it) => it.productType === productType)
              .map((it) => it.amount)
          )
        )
      );

      // 加工工場の場合は、weightBasedCo2Intensityは使用されず固定値：undefinedが格納されるためこの計算は不要となるが
      // 製紙工場の場合と計算ロジックを共通化するためそのままにしている。
      const weight = amount.times(input.productToWeightTable[productType] ?? 1);

      return {
        productType,
        co2Emission,
        weightBasedCo2Intensity: this.calcCo2Intensity({
          co2Emission,
          amount: weight,
        }),
        amountBasedCo2Intensity: this.calcCo2Intensity({
          co2Emission,
          amount: amount,
        }),
      };
    });
  }

  /**
   * ※getCategory1から呼ばれている
   */
  getByClassification(input: {
    factoryType: FactoryType;
    emissionsByMachine: EmissionByMachine[];
    productionsByMachine: ProductionByMachine[];
    wasteEmission: BigNumber;
    drainageEmission: BigNumber;
    purchasedSteamEmission?: BigNumber;
    productToWeightTable: Partial<Record<string, BigNumber>>;
  }): {
    classificationType: ClassificationType;
    co2Emission: BigNumber;
    weightBasedCo2Intensity?: BigNumber;
    amountBasedCo2Intensity?: BigNumber;
  }[] {
    const { emissionsByMachine } = input;

    const materialClassifications = materialClassificationMaster.filter(
      (it) => it.factoryType === input.factoryType
    );

    const totalAmount = total(
      ...input.productionsByMachine.map((production) =>
        total(...production.map((it) => it.amount))
      )
    );
    const totalWeight = total(
      ...input.productionsByMachine.map((production) =>
        total(
          ...production.map((it) =>
            it.amount.times(input.productToWeightTable[it.productType] ?? 1)
          )
        )
      )
    );

    const helper = (
      classificationType: ClassificationType,
      ...co2Emissions: BigNumber[]
    ) => {
      const co2Emission = total(...co2Emissions);
      return {
        classificationType,
        co2Emission,
        weightBasedCo2Intensity: this.calcCo2Intensity({
          co2Emission,
          amount: totalWeight,
        }),
        amountBasedCo2Intensity: this.calcCo2Intensity({
          co2Emission,
          amount: totalAmount,
        }),
      };
    };

    const res = [
      helper(
        "privateSteam",
        ...emissionsByMachine.map((it) => it.privateSteamCo2Emission)
      ),
      helper(
        "privatePower",
        ...emissionsByMachine.map((it) => it.privatePowerCo2Emission)
      ),
      helper(
        "purchasedPower",
        ...emissionsByMachine.map((it) => it.purchasedPowerCo2Emission)
      ),
      helper(
        "certificate",
        ...emissionsByMachine.map((it) => it.certificateCo2Emission)
      ),
      helper("waste", input.wasteEmission),
      helper("drainage", input.drainageEmission),
      helper("water", ...emissionsByMachine.map((it) => it.waterCo2Emission)),
      ...materialClassifications.map(
        ({ classificationType, materialTypes }) => {
          return helper(
            classificationType,
            ...emissionsByMachine.map((emissionByMachine) =>
              total(
                ...emissionByMachine.byMaterial
                  .filter((it) => materialTypes.includes(it.materialType))
                  .map((it) => it.co2Emission)
              )
            )
          );
        }
      ),
    ];

    // 加工の場合は「購入蒸気」を追加
    if (input.purchasedSteamEmission !== undefined) {
      res.push(helper("purchasedSteam", input.purchasedSteamEmission));
    }

    return res;
  }

  /**
   * 外から呼ばれている
   */
  getCategory1(input: {
    factoryType: Exclude<FactoryType, "user">;
    energyInput: Required<EnergyInputModel>;
    wasteInput: Required<WasteInputModel>;
    emissionFactorList: EmissionFactorModel[];
    productToWeightTable: Partial<Record<string, BigNumber>>;
    machines: MachineModel[];
    products: ProductModel[];
    amount?: BigNumber; // 単位: m2/年
  }): {
    byProduct: {
      productType: string;
      co2Emission: BigNumber;
      weightBasedCo2Intensity?: BigNumber;
      amountBasedCo2Intensity?: BigNumber;
    }[];
    byClassification: {
      classificationType: ClassificationType;
      co2Emission: BigNumber;
      weightBasedCo2Intensity?: BigNumber;
      amountBasedCo2Intensity?: BigNumber;
    }[];
  } {
    // 加工では製造に使用した蒸気・電力を入力してもらえないため、他の入力から推測する
    if (["process"].includes(input.factoryType)) {
      if (input.machines.length !== 1) {
        throw new Category1ProviderException(
          "machine-count-incorrect",
          undefined,
          undefined
        );
      }

      input.machines[0].steamConsumption = BigNumber.max(
        0,
        total(
          input.energyInput.steamPurchase.amount,
          input.energyInput.energySale.steam.negated()
        )
      );

      input.machines[0].electricPowerConsumption = BigNumber.max(
        0,
        total(
          input.energyInput.generator.occurredElectricPower,
          input.energyInput.coGenerator.occurredElectricPower,
          input.energyInput.solar.occurredElectricPower,
          input.energyInput.hydroPower.occurredElectricPower,
          ...input.energyInput.electricPowerPurchases.map((it) => it.amount),
          input.energyInput.energySale.electricPower.negated(),
          input.wasteInput.inHouseDrainageElectricPower.negated()
        )
      );
    }

    // 加工の場合に、ボイラー、コジェネが生成する熱由来の排出量を計算する
    let privateSteamCo2EmissionItems;
    let electricPowerRatio;
    if (["process"].includes(input.factoryType)) {
      // ボイラーの燃料由来（=発生蒸気由来）の排出量
      const boilerCo2Emission = total(
        ...input.energyInput.boiler.fuelConsumptions.map((fuelConsumption) => {
          const factor = input.emissionFactorList.find(
            (it) => it.target === fuelConsumption.fuelType
          )?.co2EmissionFactor;

          if (factor === undefined) {
            throw new Category1ProviderException(
              "emission-factor-not-found",
              undefined,
              undefined
            );
          }

          return fuelConsumption.amount.times(factor);
        })
      );

      // コジェネの燃料由来の排出量
      const coGeneratorTotalCo2Emission = total(
        ...input.energyInput.coGenerator.fuelConsumptions.map(
          (fuelConsumption) => {
            const factor = input.emissionFactorList.find(
              (it) => it.target === fuelConsumption.fuelType
            )?.co2EmissionFactor;

            if (factor === undefined) {
              throw new Category1ProviderException(
                "emission-factor-not-found",
                undefined,
                undefined
              );
            }

            return fuelConsumption.amount.times(factor);
          }
        )
      );
      // コジェネへの全エネルギー投入量[MJ]
      const totalEnergy = total(
        ...input.energyInput.coGenerator.fuelConsumptions.map(
          (fuelConsumption) => {
            const factor = input.emissionFactorList.find(
              (it) => it.target === `${fuelConsumption.fuelType}ToEnergy`
            )?.co2EmissionFactor;

            if (factor === undefined) {
              throw new Category1ProviderException(
                "emission-factor-not-found",
                undefined,
                undefined
              );
            }

            return fuelConsumption.amount.times(factor);
          }
        )
      );
      // コジェネの生成エネルギーのうち、電力になる比率
      const energyToElectricPowerRatio = !totalEnergy.isEqualTo(
        new BigNumber(0)
      )
        ? input.energyInput.coGenerator.occurredElectricPower // コジェネの発生電力量[kWh]
            .times(this.unitConversion)
            .div(this.powerGenerationEfficiency)
            .div(totalEnergy)
        : new BigNumber(0);
      // コジェネの熱由来の排出量
      const coGeneratorCo2Emission = coGeneratorTotalCo2Emission.times(
        BigNumber.max(0, new BigNumber(1).minus(energyToElectricPowerRatio))
      );

      privateSteamCo2EmissionItems = {
        boilerCo2Emission,
        coGeneratorCo2Emission,
      };

      // コジェネの電力割合
      electricPowerRatio = energyToElectricPowerRatio;
    }

    // 工場単位で把握したGHG排出量合計
    const {
      factor: {
        ownSteamEmissionFactor,
        ownElectricEmissionFactor,
        factoryElectricEmissionFactorWithoutCertificates:
          electricEmissionFactorWithoutCertificates,
      },
      emission: {
        totalGhgWasteEmissionByFactory: wasteEmission,
        totalGhgDrainageEmissionByFactory: drainageEmission,
      },
    } = this.emissionMathProvider.totalGhgEmissionByFactory(
      input.energyInput,
      input.wasteInput,
      input.emissionFactorList,
      electricPowerRatio
    );

    const {
      industrialWater: industrialWaterEmissionFactor,
      groundWater: groundWaterEmissionFactor,
    } = this.converter.convertToWaterEmissionFactor(input.emissionFactorList);

    const materialTypes = {
      paper: paperMaterialTypes,
      process: processMaterialTypes,
    }[input.factoryType];

    const emissionsByMachine = this.calcEmissionByMachine({
      machines: input.machines,
      energyInput: input.energyInput,
      materialTypes,
      factors: {
        ownSteamEmissionFactor,
        ownElectricEmissionFactor,
        electricEmissionFactorWithoutCertificates,
        industrialWaterEmissionFactor,
        groundWaterEmissionFactor,
        emissionFactors: input.emissionFactorList,
      },
      privateSteamCo2EmissionItems,
    });

    this.assertAmount(input.factoryType, input.amount);

    const productionsByMachine: ProductionByMachine[] =
      input.factoryType === "paper"
        ? input.machines.map((machine) =>
            machine.paperDeals.map((it) => ({
              productType: it.paperType,
              amount: it.amount,
              // 製紙の製品量は t 単位で入力するため、製品量 = 重量
              weight: it.amount,
            }))
          )
        : [
            // 加工の場合はマシン1台（工場全体が1つのマシン）だと考え、引数で受け取った総生産量を用いる
            [
              {
                productType: "processFactory",
                amount: input.amount, // 単位：m2/年
                // 加工の場合はweightは使われないが、計算ロジックの都合上値を入れる必要がある
                weight: input.amount,
              },
            ],
          ];

    // 加工の場合に、「購入蒸気」由来のCO2排出量を計算
    let purchasedSteamEmission: BigNumber | undefined;
    if (input.factoryType !== "paper") {
      const emissionFactor = input.emissionFactorList.find(
        (it) => it.target === "purchaseSteam"
      )?.co2EmissionFactor;

      if (emissionFactor === undefined) {
        throw new Category1ProviderException(
          "emission-factor-not-found",
          undefined,
          undefined
        );
      }

      purchasedSteamEmission =
        input.energyInput.steamPurchase.amount.times(emissionFactor);
    }

    return {
      byProduct: this.getByProduct({
        factoryType: input.factoryType,
        wasteEmission,
        drainageEmission,
        emissionsByMachine,
        productionsByMachine,
        productToWeightTable: input.productToWeightTable,
        products: input.products,
      }),
      byClassification: this.getByClassification({
        factoryType: input.factoryType,
        wasteEmission,
        drainageEmission,
        purchasedSteamEmission,
        emissionsByMachine,
        productionsByMachine,
        productToWeightTable: input.productToWeightTable,
      }),
    };
  }

  assertAmount(
    factoryType: Exclude<FactoryType, "user">,
    amount: BigNumber | undefined
  ): asserts amount is BigNumber {
    if (factoryType !== "paper" && !amount) {
      throw new Category1ProviderException(
        "amount-not-found",
        undefined,
        undefined
      );
    }
  }

  /**
   * EnergyInputModelの全プロパティを必須項目に変更する。
   * ※外から呼ばれている
   * @param ghgInput GHG入力情報
   * @returns 必須項目化されたEnergyInputModel
   */
  convertToRequiredEnergyInputModel(
    energyInput: EnergyInputModel | undefined
  ): Required<EnergyInputModel> {
    return {
      boiler: energyInput?.boiler ?? {
        fuelConsumptions: [],
        occurredSteam: new BigNumber(0),
      },
      generator: energyInput?.generator ?? {
        fuelConsumptions: [],
        occurredElectricPower: new BigNumber(0),
      },
      coGenerator: energyInput?.coGenerator ?? {
        fuelConsumptions: [],
        occurredSteamForSteam: new BigNumber(0),
        occurredSteamForElectricPower: new BigNumber(0),
        occurredElectricPower: new BigNumber(0),
      },
      solar: energyInput?.solar ?? {
        occurredElectricPower: new BigNumber(0),
      },
      hydroPower: energyInput?.hydroPower ?? {
        occurredElectricPower: new BigNumber(0),
      },
      energySale: energyInput?.energySale ?? {
        steam: new BigNumber(0),
        electricPower: new BigNumber(0),
      },
      electricPowerPurchases: energyInput?.electricPowerPurchases ?? [],
      steamPurchase: energyInput?.steamPurchase ?? {
        amount: new BigNumber(0),
      },
      greenCertificates: energyInput?.greenCertificates ?? [],
      jCredits: energyInput?.jCredits ?? [],
    };
  }

  /**
   * WasteInputModelの全プロパティを必須項目に変更する。
   * ※外から呼ばれている
   * @param ghgInput GHG入力情報
   * @returns 必須項目化されたWasteInputModel
   */
  convertToRequiredWasteInputModel(
    wasteInput: WasteInputModel | undefined
  ): Required<WasteInputModel> {
    return {
      wastes: wasteInput?.wastes ?? [],
      totalInHouseIncinerationCo2Emission:
        wasteInput?.totalInHouseIncinerationCo2Emission ?? new BigNumber(0),
      inHouseDrainageElectricPower:
        wasteInput?.inHouseDrainageElectricPower ?? new BigNumber(0),
      outSourceDrainageAmount:
        wasteInput?.outSourceDrainageAmount ?? new BigNumber(0),
    };
  }
}

import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { v4 as uuid } from "uuid";

import {
  GhgInputCategory4Service,
  GhgInputCategory4ServiceSubmitError,
} from "./ghgInputCategory4Service";
import {
  DeliveryForm,
  GhgInputCategory4Form,
  GhgInputCategory4TabType,
  initGhgInputCategory4Form,
} from "./ghgInputForm";
import {
  convertTransportLocalStorageToForm,
  validateBoolean,
} from "./ghgInputFormConverter";
import {
  GhgInputService,
  GhgInputServiceGetDeliveryError,
  GhgInputServiceUnSubmitError,
  LocalStorageServiceRegisterError,
} from "./ghgInputService";

import {
  Category4Api,
  GetCategory4ApiError,
} from "src/app/apis/category4-api/category4Api";
import {
  GetInputRequestApiError,
  InputRequestApi,
} from "src/app/apis/input-request-api/inputRequestApi";
import {
  getLocalStorageApi,
  GetLocalStorageError,
  LocalStorage,
  SetLocalStorageError,
} from "src/app/apis/local-storage-api";
import {
  FactoryType,
  GetInputRequests200,
  IntensityType,
} from "src/app/apis/model";
import { versionCheck } from "src/app/components/pages/FactoryListPage";
import {
  GhgInputCategory4Template,
  OTHER_KEY,
} from "src/app/components/templates";
import { envConfig, messages } from "src/app/configs";
import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { useErrorDialog } from "src/app/hooks/useErrorDialog";
import { useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { ghgInputCategory4TabNames } from "src/app/navigation/tabs";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

type GhgInputPagePathParam = {
  factoryId: string;
  year: string;
  tab: string;
};

export const GhgInputCategory4Page: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const [authState, setAuthState] = useAuthState<AuthState>();

  const params = useParams<GhgInputPagePathParam>() as GhgInputPagePathParam;

  const targetTab = ["transport"].includes(params.tab)
    ? (params.tab as GhgInputCategory4TabType)
    : "transport";

  // 入力依頼一覧（工場一覧）画面のリクエスト
  const previousPagePath = paths.input.year.replace(":year", params.year);

  const [form, setForm] = useState<GhgInputCategory4Form>(
    initGhgInputCategory4Form()
  );

  const [isUnsavedForm, setIsUnsavedForm] = useState<boolean>(false);
  const [localStorage, setLocalStorage] = useState<LocalStorage>();
  const [category4Version, setCategory4Version] = useState(0);
  const [deliveryList, setDeliveryList] = useState<DeliveryForm[]>([]);

  const updateForm: Dispatch<SetStateAction<GhgInputCategory4Form>> =
    useCallback((form) => {
      setIsUnsavedForm(true);
      setForm(form);
    }, []);

  const yearDropDownProps = useYearDropdown();

  const { dialogProps: errorDialogProps, openDialog: openErrorDialog } =
    useErrorDialog();

  const getCategory4 = async () => {
    let version = 0;
    if (yearDropDownProps?.year !== undefined) {
      const category1Api = new Category4Api();
      // SDA015:カテゴリ4取得API
      const result = await category1Api.getCategory4({
        year: yearDropDownProps.year.toFormat("yyyy"),
      });
      const factory = result.factories.find(
        (info) => info.factoryId === params.factoryId
      );
      if (factory !== undefined) {
        version = factory.version;
      }
      setCategory4Version(version);
    }
    return version;
  };

  /** 初期値取得処理 */
  const {
    data: apiRes,
    isProcessing: isLoading,
    setData: setApiRes,
  } = useAsyncEffect(async () => {
    if (yearDropDownProps?.year === undefined) {
      return;
    }

    const ghgInputService = new GhgInputService();
    let inputRequest: GetInputRequests200 | undefined;
    let localStorageInput: LocalStorage | undefined;

    try {
      // ローカルストレージ取得
      const localStorageApi = getLocalStorageApi();
      // CDP003:一次データ一時情報取得
      localStorageInput = await localStorageApi.get({
        year: yearDropDownProps.year.toFormat("yyyy"),
        factoryId: params.factoryId,
      });
      setLocalStorage(localStorageInput);

      // SDA002:GHG入力依頼詳細取得API
      const inputRequestApi = new InputRequestApi();
      inputRequest = await inputRequestApi.getInputRequest({
        year: yearDropDownProps.year,
        factoryId: params.factoryId,
      });

      const version = await getCategory4();

      if (
        // 提出期限内で提出済みのステータスかつバージョンに変更がある場合
        (inputRequest.status === "submitted" ||
          inputRequest.category4.submissionTime !== undefined) &&
        !versionCheck(localStorageInput)
      ) {
        // 結果を削除し、再度入力依頼詳細を取得
        await ghgInputService.unSubmit({
          intensityCategory: "category4",
          version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        inputRequest = await inputRequestApi.getInputRequest({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });
      }

      // 納入先一覧取得APIの呼び出し
      // 「輸送」タブを開いているときのみ呼び出す
      const deliveryList = (
        await ghgInputService.getDelivery({
          from: params.factoryId,
        })
      ).to.map((item) => {
        return {
          // 工場IDを納入先IDとして読み替える
          destinationId: item.factoryId,
          factoryName: item.factoryNameForSelf,
        };
      });
      setDeliveryList(deliveryList);

      // 入力フォームを生成
      const tmpForm = convertTransportLocalStorageToForm(
        localStorageInput?.transportInput
      );
      setForm({
        transport: {
          ...tmpForm,
          transportByRail: {
            ...tmpForm.transportByRail,
            transportByRails: tmpForm.transportByRail.transportByRails.map(
              (it) => {
                const isOther = !deliveryList.some(
                  (dl) => it.destinationId.value === dl.destinationId
                );
                return {
                  ...it,
                  destinationId: isOther
                    ? { ...it.destinationId, value: OTHER_KEY }
                    : it.destinationId,
                };
              }
            ),
          },
        },
      });
      setIsUnsavedForm(false);
    } catch (err) {
      if (
        !(err instanceof GetInputRequestApiError) &&
        !(err instanceof GetLocalStorageError) &&
        !(err instanceof GetCategory4ApiError) &&
        !(err instanceof GhgInputServiceUnSubmitError) &&
        !(err instanceof GhgInputServiceGetDeliveryError)
      ) {
        snackbar.error(messages.common.unknown);
        return;
      }

      const errType = err.type;
      switch (errType) {
        case "can-not-use":
          navigate(previousPagePath);
          snackbar.error(messages.localStorage.canNotUse);
          break;
        case "cookie-disabled":
          navigate(previousPagePath);
          snackbar.error(messages.localStorage.cookieDisabled);
          break;
        case "factory-not-found":
          navigate(previousPagePath);
          snackbar.error(messages.ghgInput.factoryNotFound);
          break;
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          break;
        case "network":
          if (err instanceof GetCategory4ApiError) {
            navigate(previousPagePath);
          }
          snackbar.error(messages.common.network);
          break;
        // 期限切れエラーと楽観ロックエラーにはならないはずなので unknown で処理する
        case "expired":
        case "conflict":
        case "unknown":
          if (err instanceof GetCategory4ApiError) {
            navigate(previousPagePath);
          }
          snackbar.error(messages.common.unknown);
          break;
        case "input-requests-not-found":
          snackbar.error(messages.ghgInput.inputRequestNotFound);
          break;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }

    return { inputRequest: inputRequest };
  }, [
    navigate,
    setAuthState,
    snackbar,
    params.factoryId,
    yearDropDownProps?.year,
    // タブが変化したら再読み込みする
    location.pathname,
  ]);

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        apiRes?.inputRequest === undefined ||
        apiRes.inputRequest.factoryType === "user"
      ) {
        return;
      }

      // 未登録内容チェック処理
      if (isUnsavedForm) {
        snackbar.error(messages.ghgInput.submit.unSaved);
        return;
      }

      try {
        const ghgInputService = new GhgInputCategory4Service();
        // CDP007:GHG算出情報確定・算定（カテゴリ4）
        await ghgInputService.submitCategory4TorchBased({
          factoryId: params.factoryId,
          year: yearDropDownProps.year,
          factoryType: apiRes.inputRequest.factoryType as Exclude<
            FactoryType,
            "user"
          >,
          companyId: authState.companyId,
          category4Version,
        });

        snackbar.success(messages.ghgInput.submit.success);

        // 入力依頼一覧に遷移する
        navigate(previousPagePath);
      } catch (err) {
        if (
          !(err instanceof GhgInputCategory4ServiceSubmitError) &&
          !(err instanceof GetInputRequestApiError)
        ) {
          snackbar.error(messages.common.unknown);
          return;
        }

        const errType = err.type;
        switch (errType) {
          case "input-requests-not-found":
            snackbar.error(messages.ghgInput.inputRequestNotFound);
            return;
          case "product-not-found":
            openErrorDialog(messages.ghgInput.submit.productNotFound);
            return;
          case "deliveries-not-found":
            openErrorDialog(messages.ghgInput.submit.deliveriesNotFound);
            return;
          case "factory-not-found":
            openErrorDialog(messages.ghgInput.submit.factoryNotFound);
            return;
          case "emission-factor-not-found":
            openErrorDialog(messages.ghgInput.submit.emissionFactorNotFound);
            return;
          case "procurement-by-supplier-not-found":
            openErrorDialog(
              messages.ghgInput.submit.procurementBySupplierNotFound
            );
            return;
          case "weight-factor-is-zero":
            openErrorDialog(messages.ghgInput.submit.weightFactorIsZero);
            return;
          case "weight-factor-incorrect":
            openErrorDialog(messages.ghgInput.submit.weightFactorIncorrect);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.submit.expired);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.submit.alreadyExist);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  const { dialogProps: submitDialogProps, openDialog: openSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.submit.confirm, onSubmit);

  const { mutate: onUnSubmit, isLoading: isUnSubmitting } = useMutation(
    async () => {
      if (yearDropDownProps === undefined || apiRes === undefined) {
        return;
      }

      try {
        const ghgInputService = new GhgInputService();
        // 登録削除処理
        await ghgInputService.unSubmit({
          intensityCategory: "category4",
          version: category4Version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        snackbar.success(messages.ghgInput.unSubmit.success);

        //GHG入力依頼詳細取得APIの呼び出し
        const inputRequestApi = new InputRequestApi();
        const res = await inputRequestApi.getInputRequest({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });
        setApiRes({ inputRequest: res });

        await getCategory4();
      } catch (err) {
        if (
          !(err instanceof GhgInputServiceUnSubmitError) &&
          !(err instanceof GetInputRequestApiError)
        ) {
          snackbar.error(messages.common.unknown);
          return;
        }

        const errType = err.type;
        switch (errType) {
          case "factory-not-found":
            snackbar.error(messages.ghgInput.factoryNotFound);
            return;
          case "input-requests-not-found":
            snackbar.error(messages.ghgInput.inputRequestNotFound);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.unSubmit.expired);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.unSubmit.conflict);
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  const { dialogProps: unSubmitDialogProps, openDialog: openUnSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.unSubmit.confirm, onUnSubmit);

  const { mutate: onRegister, isLoading: isRegistering } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        apiRes === undefined ||
        authState === undefined ||
        authState.role === "user-company"
      ) {
        return;
      }

      try {
        const ghgInputService = new GhgInputService();
        // CDP00501:一次データ登録（カテゴリ1）
        const res = await ghgInputService.register({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
          targetTab,
          form,
          setForm,
          companyType: authState.role,
          localStorage: localStorage,
        });

        snackbar.success(messages.ghgInput.registration.success);

        setIsUnsavedForm(false);
        setLocalStorage(res.res);

        window.scrollTo({ top: 0 });
      } catch (err) {
        if (!(err instanceof LocalStorageServiceRegisterError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "validation":
            snackbar.error(messages.ghgInput.registration.validationError);
            return;
          case "can-not-use":
            snackbar.error(messages.localStorage.canNotUse);
            return;
          case "cookie-disabled":
            snackbar.error(messages.localStorage.cookieDisabled);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  const { mutate: onSave, isLoading: isSaving } = useMutation(async () => {
    if (apiRes === undefined || yearDropDownProps === undefined) {
      return;
    }

    try {
      const localStorageApi = getLocalStorageApi();
      // CDP004:一時保存
      const res = await localStorageApi.set({
        factoryId: params.factoryId,
        year: yearDropDownProps.year.toFormat("yyyy"),
        targetTab,
        transportInput: {
          ...form.transport,
          transportByRail: {
            ...form.transport.transportByRail,
            transportByRails:
              form.transport.transportByRail.transportByRails.map((it) => {
                return {
                  ...it,
                  destinationId:
                    it.destinationId.value === OTHER_KEY
                      ? { ...it.destinationId, value: uuid() }
                      : it.destinationId,
                };
              }),
          },
          version:
            targetTab === "transport"
              ? envConfig.version
              : localStorage?.transportInput?.version ?? "1",
          isRegistered:
            targetTab === "transport" ? false : inputCompleted.transport,
        },
      });
      setIsUnsavedForm(false);
      setLocalStorage(res);

      snackbar.success(messages.ghgInput.save.success);
      window.scrollTo({ top: 0 });
    } catch (err) {
      if (!(err instanceof SetLocalStorageError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "can-not-use":
          snackbar.error(messages.localStorage.canNotUse);
          return;
        case "cookie-disabled":
          snackbar.error(messages.localStorage.cookieDisabled);
          return;
        case "unknown":
          snackbar.error(messages.common.unknown);
          return;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  });

  const isProcessing =
    isLoading || isSubmitting || isUnSubmitting || isRegistering || isSaving;

  const createInputCompleted = () => {
    if (
      (apiRes?.inputRequest?.status === "requested" &&
        apiRes?.inputRequest?.category4.submissionTime === undefined) ||
      apiRes?.inputRequest?.status === "submitted" ||
      apiRes?.inputRequest?.category4.submissionTime !== undefined
    ) {
      // バージョンに変更があれば false にする
      return {
        transport:
          localStorage?.transportInput?.version === envConfig.version
            ? validateBoolean(localStorage?.transportInput?.isRegistered)
            : false,
      };
    }
    return {
      transport: validateBoolean(localStorage?.transportInput?.isRegistered),
    };
  };

  const inputCompleted = createInputCompleted();

  const isStatus = {
    requested:
      (apiRes?.inputRequest?.status === "requested" ||
        apiRes?.inputRequest?.status === "in-progress") &&
      apiRes?.inputRequest?.category4.submissionTime === undefined,
    submitted:
      apiRes?.inputRequest?.status === "submitted" ||
      apiRes?.inputRequest?.category4.submissionTime !== undefined,
    confirmed: apiRes?.inputRequest?.status === "confirmed",
    expired: apiRes?.inputRequest?.status === "expired",
  };

  const isEditable =
    !isProcessing &&
    isStatus.requested &&
    apiRes?.inputRequest?.category4.intensityType !== IntensityType["in-house"];

  if (yearDropDownProps === undefined) {
    snackbar.error(messages.ghgInput.inputRequestNotFound, {
      preventDuplicate: true,
    });
    return <Navigate to={previousPagePath} />;
  }

  const basePath = `/input/${yearDropDownProps.year.toFormat("yyyy")}/${
    params.factoryId
  }/torch-based/category4`;

  if (authState === undefined || authState.role === "user-company") {
    return <Navigate to="/login" />;
  }

  const tabList = Object.values(ghgInputCategory4TabNames);
  const invalidUrl = tabList.every(
    (val) => location.pathname.endsWith(`/${val}`) === false
  );

  return (
    <GhgInputCategory4Template
      deliveryList={deliveryList}
      form={form}
      setForm={updateForm}
      companyType={authState.role}
      isEditable={isEditable}
      isUnsavedForm={isUnsavedForm}
      isProcessing={isProcessing}
      basePath={basePath}
      factoryName={apiRes?.inputRequest?.factoryNameForSelf}
      onClickBack={() => navigate(previousPagePath)}
      yearDropdownProps={yearDropDownProps}
      showUnSubmitButton={isStatus.submitted || isStatus.confirmed}
      submitButtonProps={{
        onClick: openSubmitDialog,
        disabled: !(
          // 提出できる条件
          (!isProcessing && inputCompleted.transport && isStatus.requested)
        ),
        isProcessing: isSubmitting,
      }}
      submitDialogProps={submitDialogProps}
      unSubmitButtonProps={{
        onClick: openUnSubmitDialog,
        disabled: !(
          // 提出取消できる条件
          (!isProcessing && isStatus.submitted)
        ),
        isProcessing: isUnSubmitting,
      }}
      unSubmitDialogProps={unSubmitDialogProps}
      submitButtonMessage={
        messages.ghgInput.submissionButtonDescription[
          apiRes?.inputRequest?.status === "in-progress"
            ? isStatus.requested
              ? "requested"
              : "submitted"
            : apiRes?.inputRequest?.status ?? "requested"
        ]
      }
      tabItemProps={{
        transport: {
          onClick: () => navigate(`${basePath}/transport`),
          selected: targetTab === "transport",
          checked: validateBoolean(inputCompleted.transport),
        },
      }}
      registerButtonProps={{
        onClick: onRegister,
        disabled: !(
          // 登録できる条件
          (!isProcessing && isStatus.requested)
        ),
        isProcessing: isRegistering,
      }}
      saveButtonProps={{
        onClick: onSave,
        disabled: !(
          // 一時保存できる条件
          (!isProcessing && isStatus.requested)
        ),
        isProcessing: isSaving,
      }}
      errorDialogProps={errorDialogProps}
      invalidUrl={invalidUrl}
    />
  );
};

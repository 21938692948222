import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { EChartsOption } from "echarts";
import { useMemo } from "react";

import { classifications, createTooltipIcon } from ".";

import { CompanyType } from "src/app/apis/model";
import { AppTheme } from "src/app/configs";
import { Category1IntensityByClassificationType } from "src/app/models";
import { formatIntensity } from "src/app/utils/computeForVisuals";
import { Echarts } from "src/lib/components/atoms";
import { useAspectRatio } from "src/lib/hooks/useAspectRatio";
import { create } from "src/lib/utils/domUtil";
import { sum } from "src/lib/utils/mathUtil";

const useStyles = makeStyles(() => ({
  chart: {
    width: "100%",
    height: "100%",
  },
}));

export type Category1IntensityByClassificationChartProps = {
  data: Category1IntensityByClassificationType[];
  unit: string;
  companyType: Exclude<CompanyType, "user-company">;
};

export const Category1IntensityByClassificationChart: React.FC<
  Category1IntensityByClassificationChartProps
> = (props) => {
  const theme = useTheme<AppTheme>();
  const chartOption = useMemo<EChartsOption>(() => {
    const classificationsForCompany = classifications[props.companyType];

    const topBorderRadius = (
      factory: Category1IntensityByClassificationType
    ) => {
      for (const it of classificationsForCompany.slice().reverse()) {
        const total = sum(
          ...Object.values(factory[it.middleClassificationType])
        );
        if (total > 0) {
          return it.middleClassificationType;
        }
      }
      return classificationsForCompany[0].middleClassificationType;
    };

    //グラフに表示するX軸アイテムの最大個数を設定
    const displayXItemNumber = 8;
    const option: EChartsOption = {
      textStyle: {
        fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
        fontSize: theme.typography.body2.fontSize,
      },
      grid: {
        // グラフの周りに軸ラベルのための領域を確保
        left: 0,
        right: 0,
        // スクロールバーを表示する際、余白を確保する。
        bottom: props.data.length >= displayXItemNumber ? 50 : 8,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          if (!(params instanceof Array)) {
            throw new Error("params must be array");
          }

          const factoryName = params[0].name;
          const tableContent = create("tbody", () => {});

          for (const it of params) {
            tableContent.appendChild(
              create("tr", (tr) => {
                tr.appendChild(
                  createTooltipIcon(
                    it.color === undefined ? "" : it.color.toString()
                  )
                );
                tr.appendChild(
                  create("td", (td) => {
                    td.textContent =
                      it.seriesName === undefined ? "" : it.seriesName;
                  })
                );
                tr.appendChild(
                  create("td", (td) => {
                    td.style.paddingLeft = "16px";
                    td.style.fontWeight = "bold";
                    td.textContent = formatIntensity(
                      it.value as number,
                      props.companyType
                    );
                  })
                );
              })
            );
          }

          const root = create("div", (div) => {
            div.appendChild(document.createTextNode(factoryName));
            div.appendChild(create("br", (br) => {}));
            div.appendChild(
              create("table", (table) => {
                table.appendChild(tableContent);
              })
            );
          });

          return [root];
        },
      },
      legend: {
        data: classificationsForCompany.map((it) => {
          return {
            name: it.middleClassificationName,
            icon: it.icon,
          };
        }),
        itemGap: 32,
        left: 0, // 左寄せ
      },
      xAxis: {
        type: "category",
        data: props.data.map((d) => {
          return {
            value: d.factoryName,
            textStyle: {
              fontSize: theme.typography.body2.fontSize,
            },
          };
        }),
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          margin: 16,
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        name: `(${props.unit})`,
        type: "value",
        axisLabel: {
          formatter: (value: number) => {
            return value.toLocaleString();
          },
          fontSize: theme.typography.body2.fontSize,
        },
        nameTextStyle: {
          align: "left",
          padding: parseInt(theme.spacing(3)),
        },
      },
      dataZoom:
        props.data.length > displayXItemNumber
          ? [
              {
                type: "slider",
                startValue: 0,
                // 0からスタートするため-1にする
                endValue: displayXItemNumber - 1,
                showDetail: false,
                zoomLock: true,
                height: 15,
                handleSize: 0,
                backgroundColor: "#EEEEEE",
                borderColor: "#EEEEEE",
                fillerColor: "#CCCCCC",
                brushSelect: false,
              },
            ]
          : [],
      series: classificationsForCompany.map((it, index) => {
        return {
          name: it.middleClassificationName,
          type: "bar",
          stack: "classification",
          emphasis: { focus: "series" },
          color: it.color,
          barMaxWidth: 32,
          data: props.data.map((d, i) => {
            const seriesTotal = sum(
              ...Object.values(d[it.middleClassificationType])
            );
            return {
              value: seriesTotal,
              itemStyle: {
                color: it.color,
                borderRadius:
                  topBorderRadius(d) === it.middleClassificationType
                    ? [5, 5, 0, 0]
                    : [0, 0, 0, 0],
              },
              label: {
                show: index === classificationsForCompany.length - 1,
                formatter: () => {
                  let factoryTotal = 0;
                  for (const it of classificationsForCompany) {
                    factoryTotal += sum(
                      ...Object.values(d[it.middleClassificationType])
                    );
                  }
                  return formatIntensity(factoryTotal, props.companyType);
                },
                textStyle: { fontWeight: "normal" },
                position: "top",
              },
              // series-bar.data.tooltipはドキュメントにないがなぜか動く、、
              // そもそもどのtypeでもtooltipの型がないことになってるが、
              // こう書くと動いてくれてる。
              tooltip: {
                trigger: "item",
                formatter: () => {
                  const tableContent = create("tbody", () => {});

                  it.classification.forEach((detail, detailIndex) => {
                    tableContent.appendChild(
                      create("tr", (tr) => {
                        tr.appendChild(
                          create("td", (td) => {
                            td.textContent = detail.classificationName;
                          })
                        );
                        tr.appendChild(
                          create("td", (td) => {
                            td.style.paddingLeft = "16px";
                            td.style.fontWeight = "bold";

                            const [, value] = Object.entries(
                              d[it.middleClassificationType]
                            ).find(
                              ([key]) => key === detail.classificationType
                            ) ?? ["", 0];
                            td.textContent = formatIntensity(
                              value,
                              props.companyType
                            );
                          })
                        );
                      })
                    );
                  });

                  const root = create("div", (div) => {
                    div.appendChild(createTooltipIcon(it.color));
                    div.appendChild(
                      document.createTextNode(it.middleClassificationName)
                    );
                    div.appendChild(create("br", (br) => {}));
                    div.appendChild(
                      create("table", (table) => {
                        table.appendChild(tableContent);
                      })
                    );
                  });

                  return [root];
                },
              },
            };
          }),
        };
      }),
    };
    return option;
  }, [props.companyType, props.data, props.unit, theme]);

  const classes = useStyles();
  const ref = useAspectRatio(2);
  return (
    <Box width="100%" {...{ ref }}>
      <Echarts className={classes.chart} option={chartOption} />
    </Box>
  );
};

import produce from "immer";
import { WritableDraft } from "immer/dist/internal";

import { InHouseCategory1InputForm } from "./InHouseCategory1InputPage";
import { InHouseCategory4InputForm } from "./InHouseCategory4InputPage";
import {
  EnergyForm,
  Field,
  ManufacturingForm,
  TransportForm,
  WasteForm,
} from "./ghgInputForm";

import { CompanyType } from "src/app/apis/model";
import {
  fuelTypes,
  paperWasteTypes,
  processWasteTypes,
  paperMaterialTypes,
  processMaterialTypes,
  exceptElectricPowerTransportFuelTypes,
  co2EmissionFactorTypes,
} from "src/app/codes";
import { OTHER_KEY } from "src/app/components/templates";
import { msg } from "src/app/configs";
import {
  AmountBasedCo2Intensity,
  Co2Amount,
  Co2EmissionFactor,
  DrainageMethod,
  ElectricPowerAmount,
  FuelAmount,
  MachineName,
  MaterialCo2EmissionFactor,
  PowerType,
  ProcurementWeightBySupplierFactory,
  ProductAmount,
  ProductionAmount,
  SteamAmount,
  TonKmBasedCo2Intensity,
  TransportByRailDestination,
  TransportByRailDistance,
  TransportFuelAmount,
  WasteAmount,
  WasteMethod,
  WaterAmount,
} from "src/app/domains";
import { isNotEmpty, validateAll } from "src/lib/utils/validationUtil";

// 出典
const Source = {
  Rule: validateAll(),
  Message: msg("input", "出典"),
};

export const validateEnergyForm = (
  energy: EnergyForm
): { isValid: boolean; energy: EnergyForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };
  return {
    energy: produce(energy, (energy) => {
      energy.electricPowerPurchases.forEach((electricPowerPurchase) => {
        validateField(
          electricPowerPurchase.emissionFactor,
          Co2EmissionFactor.Rule,
          true
        );
        validateField(
          electricPowerPurchase.amount,
          ElectricPowerAmount.Rule,
          true
        );
        validateField(electricPowerPurchase.source, Source.Rule, true);
      });

      if (energy.steamPurchase.isChecked) {
        validateField(energy.steamPurchase.steamAmount, SteamAmount.Rule, true);
        if (!energy.steamPurchase.emissionFactor.isChecked) {
          validateField(
            energy.steamPurchase.emissionFactor.field,
            Co2EmissionFactor.Rule,
            true
          );
        }
      }

      if (energy.boiler.isChecked) {
        for (const fuelType of fuelTypes) {
          validateField(
            energy.boiler.fuelConsumptions[fuelType],
            FuelAmount.Rule,
            true
          );
        }
        validateField(energy.boiler.occurredSteam, SteamAmount.Rule, true);
      }

      if (energy.coGenerator.isChecked) {
        for (const fuelType of fuelTypes) {
          validateField(
            energy.coGenerator.fuelConsumptions[fuelType],
            FuelAmount.Rule,
            true
          );
        }
        validateField(
          energy.coGenerator.occurredSteamForSteam,
          SteamAmount.Rule,
          true
        );
        validateField(
          energy.coGenerator.occurredSteamForElectricPower,
          SteamAmount.Rule,
          true
        );
        validateField(
          energy.coGenerator.occurredElectricPower,
          ElectricPowerAmount.Rule,
          true
        );
      }

      if (energy.generator.isChecked) {
        for (const fuelType of fuelTypes) {
          validateField(
            energy.generator.fuelConsumptions[fuelType],
            FuelAmount.Rule,
            true
          );
        }
        validateField(
          energy.generator.occurredElectricPower,
          ElectricPowerAmount.Rule,
          true
        );
      }

      if (energy.solar.isChecked) {
        validateField(
          energy.solar.occurredElectricPower,
          ElectricPowerAmount.Rule,
          true
        );
      }

      if (energy.hydroPower.isChecked) {
        validateField(
          energy.hydroPower.occurredElectricPower,
          ElectricPowerAmount.Rule,
          true
        );
      }

      if (energy.energySale.isChecked) {
        validateField(energy.energySale.steam, SteamAmount.Rule, true);
        validateField(
          energy.energySale.electricPower,
          ElectricPowerAmount.Rule,
          true
        );
      }

      if (energy.greenCertificates.isChecked) {
        energy.greenCertificates.certificates.forEach((certificate) => {
          validateField(certificate.powerType, PowerType.Rule, false);
          validateField(certificate.amount, ElectricPowerAmount.Rule, true);
        });
      }

      if (energy.jCredits.isChecked) {
        energy.jCredits.certificates.forEach((certificate) => {
          validateField(certificate.powerType, PowerType.Rule, false);
          validateField(certificate.amount, ElectricPowerAmount.Rule, true);
        });
      }
    }),
    isValid,
  };
};

export const validateWasteForm = (
  waste: WasteForm,
  companyType: Exclude<CompanyType, "user-company">
): { isValid: boolean; waste: WasteForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    waste: produce(waste, (waste) => {
      if (companyType === "paper-supplier") {
        for (const paperWasteType of paperWasteTypes) {
          validateField(
            waste.wastes[paperWasteType].method,
            WasteMethod.Rule,
            false
          );
          if (waste.wastes[paperWasteType].method.value !== "notApplicable") {
            validateField(
              waste.wastes[paperWasteType].amount,
              WasteAmount.Rule,
              true
            );
          }
        }
      }

      if (companyType === "process-supplier") {
        for (const processWasteType of processWasteTypes) {
          validateField(
            waste.wastes[processWasteType].method,
            WasteMethod.Rule,
            false
          );
          validateField(
            waste.wastes[processWasteType].amount,
            WasteAmount.Rule,
            true
          );
        }
      }

      validateField(
        waste.totalInHouseIncinerationCo2Emission,
        Co2Amount.Rule,
        true
      );

      validateField(waste.drainageMethod, DrainageMethod.Rule, false);

      switch (waste.drainageMethod.value) {
        case "inHouseDrainage":
          validateField(
            waste.inHouseDrainageElectricPower,
            ElectricPowerAmount.Rule,
            true
          );
          break;
        case "outSourceDrainage":
          validateField(waste.outSourceDrainageAmount, WaterAmount.Rule, true);
          break;
      }
    }),
    isValid,
  };
};

export const validateManufacturingForm = (
  manufacturing: ManufacturingForm,
  companyType: Exclude<CompanyType, "user-company">
): { isValid: boolean; manufacturing: ManufacturingForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    manufacturing: produce(manufacturing, (manufacturing) => {
      manufacturing.machines.forEach((machine) => {
        validateField(
          machine.industrialWaterConsumption,
          WaterAmount.Rule,
          true
        );
        validateField(machine.groundWaterConsumption, WaterAmount.Rule, true);

        if (companyType === "paper-supplier") {
          co2EmissionFactorTypes.forEach((materialType) => {
            if (
              !manufacturing.materialCo2EmissionFactor[materialType].isChecked
            ) {
              validateField(
                manufacturing.materialCo2EmissionFactor[materialType].field,
                MaterialCo2EmissionFactor.Rule,
                true
              );
            }
          });
          validateField(machine.machineName, MachineName.Rule, true);
          validateField(machine.steamConsumption, SteamAmount.Rule, true);
          validateField(
            machine.electricPowerConsumption,
            ElectricPowerAmount.Rule,
            true
          );
          paperMaterialTypes.forEach((materialType) => {
            validateField(
              machine.materialConsumptions[materialType],
              ProductAmount.Rule,
              true
            );
          });
          machine.paperDeals.forEach((it) => {
            validateField(it.field, ProductAmount.Rule, true);
          });
        }

        if (companyType !== "paper-supplier") {
          processMaterialTypes.forEach((materialType) => {
            validateField(
              machine.materialConsumptions[materialType],
              ProductAmount.Rule,
              true
            );
          });
        }
      });

      if (companyType === "process-supplier") {
        validateField(
          manufacturing.amount as WritableDraft<Field>,
          ProductionAmount.Rule,
          true
        );
      }
    }),
    isValid,
  };
};

export const validateTransportForm = (
  transport: TransportForm
): { isValid: boolean; transport: TransportForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    transport: produce(transport, (transport) => {
      for (const transportFuelType of exceptElectricPowerTransportFuelTypes) {
        if (
          transportFuelType === "cHeavyOil" &&
          !transport.shipTransportation.isChecked
        ) {
          continue;
        }
        validateField(
          transport.transportFuelConsumptions[transportFuelType],
          TransportFuelAmount.Rule,
          true
        );
      }

      validateField(
        transport.transportFuelConsumptions["electricPower"],
        ElectricPowerAmount.Rule,
        true
      );

      if (transport.transportByRail.isChecked) {
        transport.transportByRail.transportByRails.forEach((it) => {
          validateField(
            it.destinationId,
            TransportByRailDestination.Rule,
            false
          );
          if (it.railTransportAmount !== undefined) {
            validateField(
              it.railTransportAmount,
              TransportFuelAmount.Rule,
              it.destinationId.value === OTHER_KEY
            );
          }
          validateField(it.railDistance, TransportByRailDistance.Rule, true);
          validateField(it.truckDistance, TransportByRailDistance.Rule, true);
        });
      }

      validateField(
        transport.amount,
        ProcurementWeightBySupplierFactory.Rule,
        true
      );
    }),
    isValid,
  };
};

/** 自社算定排出原単位入力（カテゴリ１）バリデーション処理 */
export const validateInHouseCategory1Form = (
  form: InHouseCategory1InputForm,
  companyType: CompanyType
): { isValid: boolean; inputForm: InHouseCategory1InputForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    inputForm: produce(form, (form) => {
      if (companyType === "paper-supplier" && form.paperDeals !== undefined) {
        form.paperDeals.forEach((product) => {
          validateField(product.field, AmountBasedCo2Intensity.Rule, true);
        });
      }

      if (
        companyType === "process-supplier" &&
        form.amountBasedCo2Intensity !== undefined
      ) {
        validateField(
          form.amountBasedCo2Intensity,
          AmountBasedCo2Intensity.Rule,
          true
        );
      }
    }),
    isValid,
  };
};

/** 自社算定排出原単位入力（カテゴリ４）バリデーション処理 */
export const validateInHouseCategory4Form = (
  form: InHouseCategory4InputForm,
  companyType: CompanyType
): { isValid: boolean; inputForm: InHouseCategory4InputForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<Field>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    inputForm: produce(form, (form) => {
      if (form.tonKmBasedCo2Intensity !== undefined) {
        validateField(
          form.tonKmBasedCo2Intensity,
          TonKmBasedCo2Intensity.Rule,
          true
        );
      }
    }),
    isValid,
  };
};

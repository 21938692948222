import { drainageMethods, powerTypes, wasteMethods } from "./codes";

import { msg } from "src/app/configs";
import {
  isGreaterThan,
  isGreaterThanOrEqualTo,
  isIn,
  isInteger,
  isLessThan,
  isMatches,
  isMaxLength,
  isNumber,
  validateAll,
} from "src/lib/utils/validationUtil";

export const GeneralId = {
  MaxLength: 64,
  Pattern: /^([\x21-\x7e]{1,64})$/,
  Rule: validateAll(isMatches(/^([\x21-\x7e]{1,64})$/)),
};

export const CompanyId = GeneralId;

export const LoginId = GeneralId;

export const DeliveryId = GeneralId;

export const Password = {
  MaxLength: 64,
  Pattern:
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!"#$%'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,64}$/,
};

export const LoginPassword = {
  MaxLength: 64,
  Pattern: /^([\x21-\x7e]{1,64})$/,
};

export const MailAddress = {
  MaxLength: 256,
  Pattern:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

export const MachineName = {
  MaxLength: 10,
  Rule: validateAll(isMaxLength(10)),
  Message: msg("input", "マシン名"),
};

export const PowerType = {
  Rule: validateAll(isIn(powerTypes)),
  Message: msg("select", "電源種別"),
};

export const WasteMethod = {
  Rule: validateAll(isIn([...wasteMethods, "notApplicable"])),
  Message: msg("select", "処理方法"),
};

export const DrainageMethod = {
  Rule: validateAll(isIn(drainageMethods)),
  Message: msg("select", "処理方法"),
};

// 製品量
export const ProductAmount = {
  MaxLength: 10,
  Rule: validateAll(isMaxLength(10), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999,999"),
};

// 生産量
export const ProductionAmount = {
  MaxLength: 10,
  Rule: validateAll(isMaxLength(10), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999,999"),
};

// 経路区間別調達量（重量）
export const ProcurementWeightBySupplierFactory = {
  MaxLength: 10,
  Rule: validateAll(isMaxLength(10), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999,999"),
};

// マテリアル消費量
export const MaterialAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 蒸気量
export const SteamAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 電力量
export const ElectricPowerAmount = {
  MaxLength: 9,
  Rule: validateAll(isMaxLength(9), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "999,999,999"),
};

// 排水量
export const WaterAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// CO2排出係数
export const Co2EmissionFactor = {
  Rule: validateAll(isNumber(), isGreaterThanOrEqualTo(0)),
  Message: msg("input", "0以上の値"),
};

// 製品規格量ベースCO2排出原単位
export const AmountBasedCo2Intensity = {
  Rule: validateAll(isNumber(), isGreaterThanOrEqualTo(0)),
  Message: msg("input", "0以上の値"),
};

// トンキロベースCO2排出原単位
export const TonKmBasedCo2Intensity = {
  Rule: validateAll(isNumber(), isGreaterThanOrEqualTo(0)),
  Message: msg("input", "0以上の値"),
};

// 製品工程タブCO2排出係数
export const MaterialCo2EmissionFactor = {
  MaxLength: 5,
  Rule: validateAll(
    isMaxLength(5),
    isNumber(),
    isLessThan(1000),
    isGreaterThanOrEqualTo(0)
  ),
  Message: msg("inputNumberExclusiveUpper", "0", "1,000"),
};

// 輸送燃料消費量
export const TransportFuelAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// チェックボックス内の輸送燃料消費量
export const TransportFuelAmountInCheckBox = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(1)),
  Message: msg("inputInteger", "1", "9,999,999"),
};

// CO2排出量
export const Co2Amount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 燃料消費量
export const FuelAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 廃棄物量
export const WasteAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 調達量
export const ProcurementAmount = {
  MaxLength: 7,
  Rule: validateAll(isMaxLength(7), isInteger(), isGreaterThanOrEqualTo(0)),
  Message: msg("inputInteger", "0", "9,999,999"),
};

// 鉄道輸送納入先
export const TransportByRailDestination = {
  ...DeliveryId,
  Message: msg("select", "納入先"),
};

// 鉄道輸送距離
export const TransportByRailDistance = {
  MaxLength: 5,
  Rule: validateAll(isMaxLength(5), isInteger(), isGreaterThan(0)),
  Message: msg("inputInteger", "1", "99,999"),
};

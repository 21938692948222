import BigNumber from "bignumber.js";
import { DateTime } from "luxon";

import { ManufacturingForm } from "./ghgInputForm";
import {
  convertEnergyFormToInput,
  convertEnergyLocalStorageToForm,
  convertManufacturingFormToInput,
  convertWasteFormToInput,
  convertWasteLocalStorageToForm,
  validateBoolean,
} from "./ghgInputFormConverter";

import {
  CalculationCategory1PaperApi,
  PostCalculationCategory1PaperError,
} from "src/app/apis/calculation-category1-paper-api/calculationCategory1PaperApi";
import {
  CalculationCategory1ProcessApi,
  PostCalculationCategory1ProcessError,
} from "src/app/apis/calculation-category1-process-api/calculationCategory1ProcessApi";
import { GetProductQueryResult } from "src/app/apis/client/default";
import {
  EmissionFactorApi,
  GetEmissionFactorError,
} from "src/app/apis/emission-factor-api/emissionFactorApi";
import { getLocalStorageApi } from "src/app/apis/local-storage-api";
import { FactoryType, IntensityType } from "src/app/apis/model";
import {
  GetProductApiRequest,
  GetProductError,
  ProductApi,
} from "src/app/apis/product-api/productApi";
import { processMaterialTypes } from "src/app/codes";
import { envConfig } from "src/app/configs";
import {
  EmissionFactorModel,
  EnergyInputModel,
  MachineModel,
  ProductModel,
  WasteInputModel,
} from "src/app/models";
import {
  Category1Provider,
  Category1ProviderException,
  ConverterException,
  ConverterProvider,
} from "src/app/scope3-logic";
import { EmissionMathProvider } from "src/app/scope3-logic/category1/emission-math.provider";
import {
  ToBigNumberFromNumber,
  ToDateTimeFromString,
  ToNumberFromBigNumber,
  ToStringFromDateTime,
  ToStringYearFromDateTime,
} from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { parseNumber } from "src/lib/utils/numberUtil";

export class ServiceError<T> {
  type: T;
  cause?: unknown;

  constructor(type: T, cause?: unknown) {
    this.type = type;
    this.cause = cause;
  }
}

export class LocalStorageCategory1ServiceRegisterError extends ServiceError<
  "can-not-use" | "cookie-disabled" | "unknown" | "validation"
> {}

export class GhgInputCategory1ServiceSubmitError extends ServiceError<
  | "amount-not-found"
  | "factory-not-found"
  | "deliveries-not-found"
  | "product-not-found"
  | "emission-factor-not-found"
  | "machine-count-incorrect"
  | "weight-factor-incorrect"
  | "weight-factor-is-zero"
  | "expired"
  | "conflict"
  | "not-authenticated"
  | "network"
  | "unknown"
> {}

export class GhgInputCategory1ServiceUnSubmitError extends ServiceError<
  "expired" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputCategory1ServiceGetDeliveryError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputCategory1ServiceGetProductError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}
export class GhgInputCategory1Service {
  //NOTE: カテゴリ1TORCH算出情報入力用確定部品
  async submitCategory1TorchBased(input: {
    year: DateTime;
    factoryId: string;
    factoryType: Exclude<FactoryType, "user">;
    companyId: string;
    category1Version: number;
  }): Promise<void> {
    // ローカルストレージから値を取得
    const localStorageApi = getLocalStorageApi();
    const localStorageInput = await localStorageApi.get({
      factoryId: input.factoryId,
      year: input.year.toFormat("yyyy"),
    });

    if (
      !validateBoolean(localStorageInput.energyInput?.isRegistered) ||
      !validateBoolean(localStorageInput.wasteInput?.isRegistered) ||
      !validateBoolean(localStorageInput.manufacturingInput?.isRegistered)
    ) {
      // 前提条件が満たされてされていないとき
      // ボタンの非活性制御によりここにはこないはず
      throw new GhgInputCategory1ServiceSubmitError("unknown", undefined);
    }

    if (
      (localStorageInput as any)?.energyInput.version !== envConfig.version ||
      (localStorageInput as any)?.wasteInput.version !== envConfig.version ||
      (localStorageInput as any)?.manufacturingInput.version !==
        envConfig.version
    ) {
      // ローカルストレージのバージョンがアプリのバージョンとあっていないとき
      throw new GhgInputCategory1ServiceSubmitError("unknown", undefined);
    }

    // マスタデータの取得
    let productRes: GetProductQueryResult;

    let emissionFactorList: EmissionFactorModel[] = [];
    try {
      // 製品・規格取得
      // ※加工工場の場合、空レスポンスになる
      productRes = await this.getProduct({
        year: input.year.toFormat("yyyy"),
        factoryId: input.factoryId,
      });

      // 排出係数取得
      const emissionFactorApi = new EmissionFactorApi();
      const res = await emissionFactorApi.get({ year: input.year });

      emissionFactorList = res.emissionFactorList.map((it) => ({
        year: ToDateTimeFromString(res.year),
        target: it.target,
        co2EmissionFactor: ToBigNumberFromNumber(it.co2EmissionFactor),
        version: it.version,
      }));
    } catch (err) {
      if (
        !(err instanceof GhgInputCategory1ServiceGetProductError) &&
        !(err instanceof GetEmissionFactorError)
      ) {
        throw new GhgInputCategory1ServiceSubmitError("unknown", err);
      }

      if (err.type === "factory-not-found") {
        throw new GhgInputCategory1ServiceSubmitError("product-not-found", err);
      }

      switch (err.type) {
        case "not-authenticated":
          throw new GhgInputCategory1ServiceSubmitError(
            "not-authenticated",
            err
          );
        case "network":
          throw new GhgInputCategory1ServiceSubmitError("network", err);
        case "unknown":
          throw new GhgInputCategory1ServiceSubmitError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }

    // 計算の前準備
    const converter = new ConverterProvider();
    const category1Provider = new Category1Provider(
      converter,
      new EmissionMathProvider(converter)
    );

    // Category1
    const energyInput: Required<EnergyInputModel> =
      category1Provider.convertToRequiredEnergyInputModel(
        convertEnergyFormToInput(
          convertEnergyLocalStorageToForm(localStorageInput.energyInput)
        )
      );

    const wasteInput: Required<WasteInputModel> =
      category1Provider.convertToRequiredWasteInputModel(
        convertWasteFormToInput(
          convertWasteLocalStorageToForm(localStorageInput.wasteInput)
        )
      );

    const manufacturingInput = convertManufacturingFormToInput(
      localStorageInput.manufacturingInput as ManufacturingForm
    );

    const machines: MachineModel[] = manufacturingInput.machines;

    // 画面入力がある排出係数を設定
    manufacturingInput.materialCo2EmissionFactor.forEach((it) => {
      const targetEmissionFactor = emissionFactorList.find(
        (emissionFactor) => emissionFactor.target === it.materialType
      );
      if (targetEmissionFactor !== undefined) {
        targetEmissionFactor.co2EmissionFactor = it.co2EmissionFactor;
      }
    });
    if (energyInput.steamPurchase.emissionFactor !== undefined) {
      const targetEmissionFactor = emissionFactorList.find(
        (it) => it.target === "purchaseSteam"
      );
      if (targetEmissionFactor === undefined) {
        throw new GhgInputCategory1ServiceSubmitError(
          "emission-factor-not-found"
        );
      }
      targetEmissionFactor.co2EmissionFactor =
        energyInput.steamPurchase.emissionFactor;
    }

    // 製品・規格
    const inputProducts: ProductModel[] = productRes.products.map((it) => ({
      ...it,
      year: ToDateTimeFromString(productRes.year),
      frontLinearAreaToWeightRatio:
        it.frontLinearAreaToWeightRatio !== undefined
          ? ToBigNumberFromNumber(it.frontLinearAreaToWeightRatio)
          : undefined,
      cardBoardAreaToWeightRatio:
        it.cardBoardAreaToWeightRatio !== undefined
          ? ToBigNumberFromNumber(it.cardBoardAreaToWeightRatio)
          : undefined,
    }));

    // 加工工場の場合productResが空リストになってしまうため、inputProductsも空になってしまう。
    // Ratioの値は使われないが、inputProductsリストは計算ロジックのトリガーとして使用されるため固定値を格納しておく。
    if (input.factoryType === "process" && inputProducts.length === 0) {
      inputProducts.push({
        year: input.year,
        productType: "processFactory",
        productName: "processFactory",
        productShortName: "processFactory",
        frontLinearAreaToWeightRatio: undefined,
        cardBoardAreaToWeightRatio: undefined,
        materialTypes: processMaterialTypes,
        sortOrder: 1,
        version: 0,
      });
    }

    const productToWeightTable: Partial<Record<string, BigNumber>> = {};
    // 加工工場の場合productResは空リストになるため、forEach内は実行されない。
    productRes.products.forEach((product) => {
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (input.factoryType) {
        case "paper": {
          productToWeightTable[product.productType] = new BigNumber(1);
          break;
        }
      }
    });

    if (input.factoryType === "process") {
      productToWeightTable["processFactory"] = new BigNumber(1);
    }

    // productToWeightTable のチェック
    Object.keys(productToWeightTable).forEach((it) => {
      const target = productToWeightTable[it];
      if (target === undefined) {
        throw new GhgInputCategory1ServiceSubmitError(
          "weight-factor-incorrect"
        );
      }
      if (target.isEqualTo(new BigNumber(0))) {
        throw new GhgInputCategory1ServiceSubmitError("weight-factor-is-zero");
      }
    });

    // 計算して登録
    try {
      const category1Result = category1Provider.getCategory1({
        factoryType: input.factoryType,
        energyInput,
        wasteInput,
        emissionFactorList,
        productToWeightTable,
        machines,
        products: inputProducts,
        amount: manufacturingInput.amount,
      });
      //NOTE: カテゴリ1製紙計算結果登録
      if (input.factoryType === "paper") {
        const calculationCategory1PaperApi = new CalculationCategory1PaperApi();
        await calculationCategory1PaperApi.postCalculationCategory1Paper(
          {
            year: ToStringYearFromDateTime(input.year),
            companyId: input.companyId,
            factoryId: input.factoryId,
            byProduct: category1Result.byProduct.map((byProduct) => ({
              ...byProduct,
              co2Emission: ToNumberFromBigNumber(byProduct.co2Emission),
              weightBasedCo2Intensity:
                byProduct.weightBasedCo2Intensity !== undefined
                  ? ToNumberFromBigNumber(byProduct.weightBasedCo2Intensity)
                  : undefined,
              amountBasedCo2Intensity:
                byProduct.amountBasedCo2Intensity !== undefined
                  ? ToNumberFromBigNumber(byProduct.amountBasedCo2Intensity)
                  : undefined,
            })),
            byClassification: category1Result.byClassification.map(
              (byClassification) => ({
                ...byClassification,
                co2Emission: ToNumberFromBigNumber(
                  byClassification.co2Emission
                ),
                weightBasedCo2Intensity:
                  byClassification.weightBasedCo2Intensity !== undefined
                    ? ToNumberFromBigNumber(
                        byClassification.weightBasedCo2Intensity
                      )
                    : undefined,
                amountBasedCo2Intensity:
                  byClassification.amountBasedCo2Intensity !== undefined
                    ? ToNumberFromBigNumber(
                        byClassification.amountBasedCo2Intensity
                      )
                    : undefined,
              })
            ),
            submissionTime: ToStringFromDateTime(DateTime.now().toUTC()),
            intensityType: IntensityType["torch-based"],
            version: input.category1Version,
            appVersion: parseNumber(envConfig.version),
          },
          { intensityType: "torch-based" }
        );
      }

      //NOTE: カテゴリ1加工計算結果登録
      if (input.factoryType === "process") {
        const calculationCategory1ProcessApi =
          new CalculationCategory1ProcessApi();
        await calculationCategory1ProcessApi.postCalculationCategory1Process(
          {
            year: ToStringYearFromDateTime(input.year),
            companyId: input.companyId,
            factoryId: input.factoryId,
            co2Emission:
              category1Result.byProduct[0].co2Emission !== undefined
                ? ToNumberFromBigNumber(
                    category1Result.byProduct[0].co2Emission
                  )
                : undefined,
            // 加工工場・カテゴリ1の場合weightBasedCo2Intensityは使われないため固定値：undefinedを入れる。
            weightBasedCo2Intensity: undefined,
            amountBasedCo2Intensity:
              category1Result.byProduct[0].amountBasedCo2Intensity !== undefined
                ? ToNumberFromBigNumber(
                    category1Result.byProduct[0].amountBasedCo2Intensity
                  )
                : undefined,
            byClassification: category1Result.byClassification.map(
              (byClassification) => ({
                ...byClassification,
                co2Emission: ToNumberFromBigNumber(
                  byClassification.co2Emission
                ),
                // 加工工場・カテゴリ1の場合weightBasedCo2Intensityは使われないため固定値：undefinedを入れる。
                weightBasedCo2Intensity: undefined,
                amountBasedCo2Intensity:
                  byClassification.amountBasedCo2Intensity !== undefined
                    ? ToNumberFromBigNumber(
                        byClassification.amountBasedCo2Intensity
                      )
                    : undefined,
              })
            ),
            submissionTime: ToStringFromDateTime(DateTime.now().toUTC()),
            intensityType: IntensityType["torch-based"],
            version: input.category1Version,
            appVersion: parseNumber(envConfig.version),
          },
          { intensityType: "torch-based" }
        );
      }
    } catch (err) {
      if (
        !(err instanceof PostCalculationCategory1PaperError) &&
        !(err instanceof PostCalculationCategory1ProcessError) &&
        !(err instanceof ConverterException) &&
        !(err instanceof Category1ProviderException)
      ) {
        throw new GhgInputCategory1ServiceSubmitError("unknown", err);
      }

      if (err instanceof ConverterException) {
        throw new GhgInputCategory1ServiceSubmitError(
          "emission-factor-not-found",
          err
        );
      }

      const errType = err.type;
      switch (errType) {
        case "not-authenticated":
          throw new GhgInputCategory1ServiceSubmitError(
            "not-authenticated",
            err
          );
        case "network":
          throw new GhgInputCategory1ServiceSubmitError("network", err);
        case "unknown":
          throw new GhgInputCategory1ServiceSubmitError("unknown", err);
        case "expired":
          throw new GhgInputCategory1ServiceSubmitError("expired", err);
        case "factory-not-found":
          throw new GhgInputCategory1ServiceSubmitError(
            "factory-not-found",
            err
          );
        case "emission-factor-not-found":
          throw new GhgInputCategory1ServiceSubmitError(
            "emission-factor-not-found",
            err
          );
        case "machine-count-incorrect":
          throw new GhgInputCategory1ServiceSubmitError(
            "machine-count-incorrect",
            err
          );
        case "unexpected":
          throw new GhgInputCategory1ServiceSubmitError("unknown", err);
        case "conflict":
          throw new GhgInputCategory1ServiceSubmitError("conflict", err);
        case "amount-not-found":
          throw new GhgInputCategory1ServiceSubmitError(
            "amount-not-found",
            err
          );
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }
  }

  /**
   * 製品・規格一覧を取得する
   */
  async getProduct(
    input: GetProductApiRequest
  ): Promise<GetProductQueryResult> {
    try {
      const productApi = new ProductApi();
      const productList = await productApi.getProduct(input);
      return productList;
    } catch (err) {
      if (!(err instanceof GetProductError)) {
        throw new GhgInputCategory1ServiceGetProductError("unknown", err);
      }
      switch (err.type) {
        case "not-authenticated":
          throw new GhgInputCategory1ServiceGetProductError(
            "not-authenticated",
            err
          );
        case "factory-not-found":
          throw new GhgInputCategory1ServiceGetProductError(
            "factory-not-found",
            err
          );
        case "network":
          throw new GhgInputCategory1ServiceGetProductError("network", err);
        case "unknown":
          throw new GhgInputCategory1ServiceGetProductError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import BigNumber from "bignumber.js";
import { DateTime } from "luxon";
import { rest } from "msw";

import { FactoryType, IntensityType, MaterialType } from "src/app/apis/model";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InHouseCategory1InputMock = () => [
  ...getProductsMock,
  ...getPaperFactoryCategory1Mock,
  ...getProcessFactoryCategory1Mock,
  ...postCalculationCategory1PaperMock,
  ...postCalculationCategory1ProcessMock,
  ...deleteCalculationMock,

  // ...getMinMaxProductsMock,

  /**
   * 製品・規格情報取得APIのエラー
   */
  // ...getProductsNetworkMock,
  // ...getProductsTimeoutMock,
  // ...getProductsUnknownMock,
  // ...getProductsERR000000Mock,
  // ...getProductsERR000001Mock,
  // ...getProductsERR000002Mock,
  // ...getProductsERR000003Mock,
  // ...getProductsERR000004Mock,
  // ...getProductsERR018000Mock,

  /**
   * 製紙工場カテゴリ1取得APIのエラー
   */
  // ...getPaperFactoryCategory1NetworkMock,
  // ...getPaperFactoryCategory1TimeoutMock,
  // ...getPaperFactoryCategory1UnknownMock,
  // ...getPaperFactoryCategory1ERR000000Mock,
  // ...getPaperFactoryCategory1ERR000001Mock,
  // ...getPaperFactoryCategory1ERR000002Mock,
  // ...getPaperFactoryCategory1ERR000003Mock,
  // ...getPaperFactoryCategory1ERR000004Mock,

  /**
   * 加工工場カテゴリ1取得APIのエラー
   */
  // ...getProcessFactoryCategory1NetworkMock,
  // ...getProcessFactoryCategory1TimeoutMock,
  // ...getProcessFactoryCategory1UnknownMock,
  // ...getProcessFactoryCategory1ERR000000Mock,
  // ...getProcessFactoryCategory1ERR000001Mock,
  // ...getProcessFactoryCategory1ERR000002Mock,
  // ...getProcessFactoryCategory1ERR000003Mock,
  // ...getProcessFactoryCategory1ERR000004Mock,

  /**
   * 製紙工場計算結果登録（カテゴリ１）APIのエラー
   */
  // ...postCalculationCategory1PaperNetworkMock,
  // ...postCalculationCategory1PaperTimeoutMock,
  // ...postCalculationCategory1PaperUnknownMock,
  // ...postCalculationCategory1PaperERR000000Mock,
  // ...postCalculationCategory1PaperERR000001Mock,
  // ...postCalculationCategory1PaperERR000002Mock,
  // ...postCalculationCategory1PaperERR000003Mock,
  // ...postCalculationCategory1PaperERR000004Mock,
  // ...postCalculationCategory1PaperERR024001Mock,
  // ...postCalculationCategory1PaperERR024002Mock,
  // ...postCalculationCategory1PaperERR024003Mock,
  // ...postCalculationCategory1PaperERR024004Mock,
  // ...postCalculationCategory1PaperERR024005Mock,

  /**
   * 加工工場計算結果登録（カテゴリ１）APIのエラー
   */
  // ...postCalculationCategory1ProcessNetworkMock,
  // ...postCalculationCategory1ProcessTimeoutMock,
  // ...postCalculationCategory1ProcessUnknownMock,
  // ...postCalculationCategory1ProcessERR000000Mock,
  // ...postCalculationCategory1ProcessERR000001Mock,
  // ...postCalculationCategory1ProcessERR000002Mock,
  // ...postCalculationCategory1ProcessERR000003Mock,
  // ...postCalculationCategory1ProcessERR000004Mock,
  // ...postCalculationCategory1ProcessERR025001Mock,
  // ...postCalculationCategory1ProcessERR025002Mock,
  // ...postCalculationCategory1ProcessERR025003Mock,
  // ...postCalculationCategory1ProcessERR025004Mock,
  // ...postCalculationCategory1ProcessERR025005Mock,

  /**
   * 計算結果削除APIのエラー
   */
  // ...deleteCalculationNetworkMock,
  // ...deleteCalculationTimeoutMock,
  // ...deleteCalculationUnknownMock,
  // ...deleteCalculationERR000000Mock,
  // ...deleteCalculationERR000001Mock,
  // ...deleteCalculationERR000002Mock,
  // ...deleteCalculationERR000003Mock,
  // ...deleteCalculationERR000004Mock,
  // ...deleteCalculationERR020000Mock,
  // ...deleteCalculationERR020001Mock,
  // ...deleteCalculationERR020002Mock,
  // ...deleteCalculationERR020003Mock,
  // ...deleteCalculationERR020004Mock,
  // ...deleteCalculationERR020005Mock,
  // ...deleteCalculationERR020006Mock,
  // ...deleteCalculationERR020007Mock,
];

const paperNames = [
  "一般中芯",
  "強化芯",
  "Cライナー",
  "Kライナー",
  "白ライナー",
  "コートボール原紙",
  "その他",
];

const minMaxPaperNames = [
  "あ",
  "あいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえ",
  "Cライナー",
  "Kライナー",
  "白ライナー",
  "コートボール原紙",
  "その他",
];

const paperShortNames = [
  "一般中芯",
  "強化芯",
  "Cライナー",
  "Kライナー",
  "白ライナー",
  "コートボール原紙",
  "その他",
];

const paperTypes = [
  "generalCorrugationMedium",
  "reinforcedCorrugationMedium",
  "cLinear",
  "kLinear",
  "whiteLinear",
  "coatBoard",
  "others",
];

const getProductsMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        factoryId: "hokkaido",
        products: paperNames.map((it, i) => ({
          productType: paperTypes[i],
          productName: it,
          productShortName: paperShortNames[i],
          sortOrder: i,
          materialTypes: [MaterialType.usedPaper],
          cardBoardAreaToWeightRatio: new BigNumber(0.1).times(i).toNumber(),
          version: 0,
        })),
      })
    );
  }),
];

const getMinMaxProductsMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        factoryId: "hokkaido",
        products: minMaxPaperNames.map((it, i) => ({
          productType: paperTypes[i],
          productName: it,
          productShortName: paperShortNames[i],
          sortOrder: i,
          materialTypes: [MaterialType.usedPaper],
          version: 0,
        })),
      })
    );
  }),
];

const getPaperFactoryCategory1Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "company-id-01",
        year: DateTime.fromFormat("2021", "yyyy"),
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: FactoryType.paper,
            byProduct: paperNames.map((it, i) => ({
              productType: paperTypes[i],
              productName: it,
              productShortName: paperShortNames[i],
              sortOrder: i,
              co2Emission: 0,
              weightBasedCo2Intensity: 0,
              amountBasedCo2Intensity: 100 + i,
            })),
            byClassification: [],
            intensityType: IntensityType["in-house"],
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: FactoryType.paper,
            byProduct: paperNames.map((it, i) => ({
              productType: paperTypes[i],
              productName: it,
              productShortName: paperShortNames[i],
              sortOrder: i,
              co2Emission: 0,
              weightBasedCo2Intensity: 0,
              amountBasedCo2Intensity: 0,
            })),
            byClassification: [],
            intensityType: IntensityType["in-house"],
            version: 0,
            deleteFlag: true,
          },
        ],
      })
    );
  }),
];

const getProcessFactoryCategory1Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "company-id-02",
        year: DateTime.fromFormat("2021", "yyyy"),
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: FactoryType.process,
            co2Emission: 0,
            weightBasedCo2Intensity: 0,
            amountBasedCo2Intensity: 100,
            byClassification: [],
            intensityType: IntensityType["in-house"],
            version: 1,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: FactoryType.process,
            co2Emission: 0,
            weightBasedCo2Intensity: 0,
            amountBasedCo2Intensity: 0,
            byClassification: [],
            intensityType: IntensityType["in-house"],
            version: 0,
            deleteFlag: true,
          },
        ],
      })
    );
  }),
];

const postCalculationCategory1PaperMock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(201, "Mocked status"));
  }),
];

const postCalculationCategory1ProcessMock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(201, "Mocked status"));
  }),
];

const deleteCalculationMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204, "Mocked status"));
  }),
];

const getProductsUnknownMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsNetworkMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsTimeoutMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR000000Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR000001Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR000002Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR000003Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR000004Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const getProductsERR018000Mock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR018000",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1UnknownMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1NetworkMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1TimeoutMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1ERR000000Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1ERR000001Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1ERR000002Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1ERR000003Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const getPaperFactoryCategory1ERR000004Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1UnknownMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1NetworkMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1TimeoutMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1ERR000000Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1ERR000001Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1ERR000002Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1ERR000003Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const getProcessFactoryCategory1ERR000004Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperUnknownMock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperNetworkMock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperTimeoutMock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR000000Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR000001Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR000002Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR000003Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR000004Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR024001Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR024001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR024002Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR024002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR024005Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR024005",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR024004Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR024004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1PaperERR024003Mock = [
  rest.post("*/calculation/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR024003",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessUnknownMock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessNetworkMock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessTimeoutMock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR000000Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR000001Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR000002Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR000003Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR000004Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR025001Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR025001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR025002Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR025002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR025005Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR025005",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR025004Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR025004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory1ProcessERR025003Mock = [
  rest.post("*/calculation/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR025003",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationUnknownMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationNetworkMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationTimeoutMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000000Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000001Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000002Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000003Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000004Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020000Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020000",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020001Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020001",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020004Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020004",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020005Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020005",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020006Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020006",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020007Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020007",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020003Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR020003",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020002Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR020002",
        message: "Mocked message",
      })
    );
  }),
];
